.task-panel {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  z-index: 100000;
  &__top-container {
    height: calc(100vh - 185px);
  }
  &__control {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    border-top: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
    width: 100%;
    flex-shrink: 0;
    background: #ffffff;
    z-index: 10;
  }
}
