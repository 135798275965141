.row {
  display: flex;
  flex-wrap: wrap;
  // margin-right: -15px;
  // margin-left: -15px;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8-l {
  flex: 0 0 67.5%;
  max-width: 67.5%;
}
.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-1-5 {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

// .col-2-5 {
//   flex: 0 0 20%;
//   max-width: 20%;
// }
.col-2-5 {
  flex: 0 0 20.8333333334%;
  max-width: 20.8333333334%;
}

// .col-8 {
//   flex: 0 0 8.3333333333%;
//   max-width: 8.3333333333%;
// }

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-8-l,
.col-auto {
  position: relative;
  width: 100%;
  // padding-right: 15px;
  // padding-left: 15px;
}

//CONTAINER
.container {
  width: 100%;
  padding: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
    // max-width: 1253px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1257px;
  }
}
