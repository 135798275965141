.duplicate-page-list-item {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  justify-items: start;
  width: 100%;
  padding: 0.25rem 0.5rem;

  &:hover,
  &.active {
    background: var(--platform-purple-tint, #f8f8fb);
    border-radius: 0.25rem;
  }

  &__label-container {
    display: grid;
    grid-auto-flow: column;
    width: 100%;
    column-gap: 0.5rem;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    justify-items: flex-start;
    .deleted {
      path {
        stroke: #ee0b0b;
        fill: #ee0b0b;
      }
    }
  }

  &__label {
    color: var(--platform-grayscale-90, #3c3a41);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
  }

  &__description {
    color: var(--platform-grayscale-55, #838188);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__action {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
    svg path {
      stroke: #838188;
    }
  }
}
