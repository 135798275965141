.workspace {
}

.service__content {
  padding: 20px 40px 40px;
  overflow: hidden;
  .content-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .left {
      margin-right: auto;
      .ctitle {
        color: #0e1010;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
      }
      .stitle {
        color: #7e8180;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
      }
    }
    .right {
      border-radius: 12px;
      background: #e7f2f2;
      padding: 20px 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .onboard {
        .ctitle {
          color: #0e1010;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 25.56px;
        }
        .stitle {
          color: #4a4c4c;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20.44px;
        }
      }
      .guide-btn {
        margin-left: 40px;
        .skip {
          text-decoration: underline;
          margin-right: 20px;
          color: #0e1010;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
  .tabs{
    .tabs-item:first-of-type {
      padding: 10px 20px;
    }
  }
}
