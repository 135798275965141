.dpage {
}
.job-page__content {
  padding: 40px 40px 0;
 
  width: 100%;
  .content-body {
    margin: 20px 0 0;
    height: calc(100vh - 232px);
    overflow: auto;
  }
  .table-wrapper {
    height: calc(100vh - 213px); // Todo
  }
}
