.icon-btn {
  background: #4c35de;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  &.circle {
    border-radius: 100px;
  }
}
