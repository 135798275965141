.popup-menu {
  position: absolute;
  left: 0;
  top: 34px;
  min-width: 260px;
  background: #ffffff;
  border: 1px solid #e5e4eb;
  box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 8px 16px !important;
  z-index: 1000;
  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
  .heading {
    padding: 8px 0px 12px 0px;
    .title {
      color: var(--Platform-Grayscale-90, #3c3a41);
      font-family: 'Helvetica Neue';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 128.571% */
      text-align: left;
    }
    .sub-title {
      font-size: 12px;
      color: #56555c;
      margin-top: 5px;
    }
  }
  .menu-list {
    color: #36343b;
    .list-item {
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0px;
      cursor: pointer;
      &:hover {
        color: var(--Platform-Purple-Dark, #4d29db);
      }
      .enclosed-icon {
        margin-right: 15px;
      }
      .page {
        text-align: right;
      }
    }
  }
}

.workflow-select {
  position: relative;
}

.enclosed-icon {
  align-items: center;
  border-radius: 50%;
  // cursor: pointer;
  display: flex;
  justify-content: center;
}

.summary-review {
  .file-review-center-view-main {
    height: calc(100vh - 136px);
  }

  .job-processing {
    width: 100%;
    height: calc(100vh - 72px);
    display: flex;
    justify-content: center;
    align-items: center;
    &-container {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--number-spacing-s, 1rem);
      height: 100%;
      svg path {
        stroke: #18161b;
        stroke-width: 0.6;
      }
      .p-title {
        color: var(--Platform-Black, #18161b);
        text-align: center;
        font-family: 'Helvetica Neue';
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.625rem;
      }
      .p-subtitle {
        color: var(--Platform-Grayscale-55, #838188);
        text-align: center;
        font-family: 'Helvetica Neue';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 150% */
      }
      &-do {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        button {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.5rem 1.5rem;
          gap: 0.75rem;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          svg path {
            stroke: #ffffff;
          }
        }
      }
    }
  }
}
