.download-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-right: 25px;
  span {
    margin-right: 10px;
  }
  &.disabled {
    color: var(--Platform-Grayscale-40, #a2a1a8);
    svg path {
      stroke: #a2a1a8;
    }
  }
}
