.noservice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  .button {
    margin-top: 20px;
  }
}
.service__list-container {
  .service__list {
    // height: calc(100vh - 310px);
    // overflow: auto;
    grid-auto-rows: min-content;
  }
}
