.member_avatar,
.member_avatar-letter {
  border-radius: 50%;
  filter: drop-shadow(0px 3px 10px rgba(255, 255, 255, 0.25));
}
.member_avatar-letter {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 32px;
  color: var(--color-grayscale-90, #0e1010);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
}
