.page-msg {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--number-spacing-s, 1rem);
  .p-title {
    color: var(--Platform-Black, #18161b);
    text-align: center;
    font-family: 'Helvetica Neue';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.625rem;
  }
  .p-subtitle {
    color: var(--Platform-Grayscale-55, #838188);
    text-align: center;
    font-family: 'Helvetica Neue';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }
}
