.job-page__content {
  .content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .page-title {
      .title {
        color: #18161b;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 700;
      }
      .subtitle {
        color: #838188;
        font-size: 0.86rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 1.5rem;
      .input-wrapper.search-bar {
        input {
          padding: 0.5rem 1rem !important;
          height: 2.5rem;
          width: 25rem;
          border-radius: 0.375rem;
          &:focus,
          &:active {
            border: 1px solid #e5e4eb;
          }
        }
      }
      .filter-btn {
        height: 2.5rem;
        padding: 0rem 1rem;
        border-radius: 0.375rem;
      }
    }
  }
}
