.textfield {
  display: grid;
  grid-template-columns: 1fr;
  place-content: start;
  place-items: start;
  gap: 0.5rem;
  width: 100%;

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__main-label {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 146%;
    letter-spacing: -0.14px;
    color: #838188;
  }

  &__secondary-label {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 146%;
    letter-spacing: -0.14px;
    color: #a7aaa9;
  }

  &__input-container {
    display: grid;
    align-items: center;
    align-content: center;
    justify-items: flex-start;
    width: 100%;
    border-radius: 0.375rem;
    border-width: 1px;
    border-style: solid;

    // grid layout
    &--with-start-and-end-icon-layout {
      grid-template-columns: auto repeat(1, minmax(0, 1fr)) auto;
      justify-content: flex-start;
    }

    &--with-start-icon-layout {
      grid-template-columns: auto repeat(1, minmax(0, 1fr));
      justify-content: flex-start;
    }

    &--with-end-icon-layout {
      grid-auto-flow: column;
      justify-content: space-between;
    }

    &--default-layout {
      grid-template-columns: 1fr;
      justify-content: flex-start;
    }

    // field size
    &--m-size {
      height: 3rem;
      padding: 0.875rem 1rem;
    }
    &--default-size {
      height: 2.5rem;
      padding: 0.75rem 0.75rem;
    }

    // variant
    &--error-variant {
      border-color: #ee0b0b;
    }
    &--focus-variant {
      border-color: #0e1010;
    }
    &--default-variant {
      border-color: #c8cbca;
    }

    // disabled mode

    &--disabled-mode {
      background-color: #ffffff;
    }

    &--default-mode {
      background-color: #ffffff;
    }
  }

  &__input {
    outline: none;
    width: 100%;
    background-color: #ffffff;
    font-size: 0.875rem;
    color: #0e1010;
    font-weight: 400;
    line-height: 146%;
    letter-spacing: -0.14px;
    &::placeholder {
      background-color: #ffffff;
      font-size: 0.875rem;
      color: #a7aaa9;
      font-weight: 400;
      line-height: 146%;
      letter-spacing: -0.14px;
    }
    &:disabled {
      background-color: #ffffff;
      font-size: 0.875rem;
      color: #a7aaa9;
      font-weight: 400;
      line-height: 146%;
      letter-spacing: -0.14px;
    }
  }

  &__helper-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: -0.13px;

    &--error-variant {
      color: #ee0b0b;
    }
    &--default-variant {
      color: #a7aaa9;
    }
  }
}
