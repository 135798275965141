.boundary-entries-dd {
  position: absolute;
  top: 18px;
  left: 0px;
  z-index: 1000;
  border-radius: var(--number-radius-s, 0.5rem);
  border: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
  background: var(--color-grayscale-10, #fff);
  box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
  padding: 0.5rem 1rem;
  max-height: 32rem;
  width: 240px;
  overflow: auto;
  &__section {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin: 0.5rem 0;
  }
  &__header {
    color: var(--Platform-Grayscale-90, #3c3a41);
    font-family: 'Helvetica Neue';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
    svg {
      margin-left: auto;
      cursor: pointer;
      path {
        stroke: #3c3a41;
      }
    }
  }
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--Platform-Grayscale-90, #3c3a41);
    font-family: 'Helvetica Neue';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    cursor: pointer;
    &:hover {
      color: var(--Platform-Purple-Dark, #4d29db);
    }
    .text {
      max-width: 150px;
    }
  }
}
