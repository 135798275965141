.company-service {
  color: #838188;
  font-style: normal;
  font-weight: 400;
  // width: auto !important;
  .company-type {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__name {
    display: block;
    color: #3c3a41;
    text-overflow: ellipsis;
    font-size: 0.98rem;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    // max-width: 150px;
    // max-width: 260px;
    max-width: 224px;
  }
  &__type {
    display: block;
    color: #838188;
    text-overflow: ellipsis;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 18px;
  }
  &__service {
    overflow: hidden;
    color: var(--Platform-Grayscale-55, #838188);
    text-overflow: ellipsis;
    font-family: 'Helvetica Neue';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 157.143% */
    text-wrap: nowrap;
  }
}
