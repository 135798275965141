.user-det {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  .acct-det {
    width: 110px;
    margin-left: 10px;
    .usr-name {
      color: #4a4c4c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      text-align: left;
    }
    .usr-email {
      color: #7e8180;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
    }
  }
}
