*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  box-sizing: border-box;
  font-family:
    'Helvetica Neue',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button:disabled {
  // background-color: #e5e4eb !important;
  // color: #cccad1 !important;
  background-color: #f2f1f5 !important;
  color: #cccad1 !important;
  cursor: not-allowed;
  svg path {
    stroke: #cccad1 !important;
  }
}

.btn {
  padding: 5px 10px;
  margin: 0 5px;
  height: 30px;
}

hr {
  border: none;
  height: 1px;
  background-color: #e5e4eb;
}

.menu-bar__withlogo {
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .logout {
    cursor: pointer;
  }
  .logo {
    width: 200px;
    // height: 40px;
  }
}

/* Ellipsis */
.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

.multiline-word {
  inline-size: 250px;
  overflow-wrap: break-word;
  hyphens: manual;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* React tool tip*/

.react-tooltip {
  z-index: 99999999 !important;
}

.tooltip {
  border-radius: var(--number-radius-xxs, 0.25rem);
  border: 1px solid #e5e4eb;
  background: #fff;
  box-shadow: 0px 7px 8px -3px rgba(0, 0, 0, 0.04);
  font-size: 0.75rem;
  color: #18161b;
  font-family: Helvetica Neue;
  z-index: 1000;
  padding: 0.375rem var(--number-spacing-xs, 0.75rem) !important;
  .react-tooltip-arrow {
    box-shadow: 0px 7px 8px -3px rgba(0, 0, 0, 0.04);
    border: 1px solid #e5e4eb;
  }
}

.tooltip-dark {
  border-radius: var(--number-radius-xxs, 0.25rem);
  border: 1px solid #18161b;
  background: #18161b;
  box-shadow: 0px 7px 8px -3px rgba(0, 0, 0, 0.04);
  font-size: 0.75rem;
  color: #fff;
  font-family: Helvetica Neue;
  z-index: 1000;
  .react-tooltip-arrow {
    box-shadow: 0px 7px 8px -3px rgba(0, 0, 0, 0.04);
    border: 1px solid #18161b;
  }
}
.react-tooltip__place-right-start,
.react-tooltip__place-right,
.react-tooltip__place-right-end {
  .react-tooltip-arrow {
    border-top: none;
    border-right: none;
  }
}
.react-tooltip__place-top-start,
.react-tooltip__place-top,
.react-tooltip__place-top-end {
  .react-tooltip-arrow {
    border-top: none;
    border-left: none;
  }
}

.react-tooltip__place-bottom-start,
.react-tooltip__place-bottom,
.react-tooltip__place-bottom-end {
  .react-tooltip-arrow {
    border-bottom: none;
    border-right: none;
  }
}
.react-tooltip__place-left-start,
.react-tooltip__place-left,
.react-tooltip__place-left-end {
  .react-tooltip-arrow {
    border-top: none;
    border-left: none;
  }
}

[contenteditable='true']:empty:before {
  color: #a2a1a8;
  content: attr(placeholder);
  display: block;
}
