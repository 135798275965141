.input-with-label {
  .label {
    margin-bottom: 10px;
    color: #7e8180;
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
  }
  &.err input {
    border-color: #e0392a;
  }
  .inpt-err {
    color: #e0392a;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 300;
    margin-top: 5px;
  }
}
