.progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__text-container {
    display: grid;
    grid-auto-flow: column;
    column-gap: 0.5rem;
    justify-content: flex-start;
    justify-items: flex-start;
    align-items: center;
    align-content: center;
  }

  &__title {
    color: var(--platform-grayscale-90, #3c3a41);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  &__page {
    color: var(--platform-grayscale-55, #838188);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  &__status-bar {
    display: flex;
    width: 96px;
    height: 24px;
    padding: 2px;
    align-items: flex-start;
    gap: var(--number-spacing-xxs, 8px);
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--platform-grayscale-10, #e5e4eb);
    background: var(--platform-white, #fff);
    position: relative;
  }

  &__bar {
    height: 100%;
    border-radius: 2px;
    background: var(--platform-purple-basic, #7856ff);
  }

  &__status-percentage {
    color: var(--platform-grayscale-90, #3c3a41);
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    position: absolute;
    top: 0.25rem;
    right: 0;
    left: 0;
  }
}
