.service-it-lst {
  padding: 16px 0;
  margin-top: auto;
  &.mt-0{
    margin-top: 0px;
  }
  .service-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;

    &:last-child{
      padding-bottom: 0px;
    }

    svg {
      margin-right: 10px;
    }
    span {
      color: #505151;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
