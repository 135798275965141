@import '../PageZoom/style';
@import '../PagePin/style';

.page-card {
  display: flex;
  flex-direction: column;

  position: relative;
  padding: 2rem 0.75rem 0rem 0.75rem;
  border-radius: 0.5rem;
  border: 4px solid var(--platform-grayscale-5, #f2f1f5);
  width: 100%;
  aspect-ratio: 0.75;

  &.selected {
    // padding-top: 1.5rem;
    // box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.10);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  &-image-wrapper {
    &-image {
      height: 100%;
    }
  }

  &-footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0 0.5rem 0rem;
    span {
      font-size: 0.76rem;
      font-style: normal;
      font-weight: 400;
      color: var(--platform-grayscale-55, #838188);
    }
    .card-detail {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      &__status {
        margin-right: 2rem;
        font-weight: 500;
        &.done {
          color: var(--color-system-success-primary, #06b54c);
        }
        &.incomplete {
          color: var(--platform-system-warning-basic, #e1b000);
        }
      }
    }
    .card-page__no {
      font-size: 0.76rem;
      font-style: normal;
      font-weight: 400;
      color: var(--platform-grayscale-55, #838188);
      text-align: center;
      width: 100%;
      margin-left: 20px;
    }
    .card-page__rem {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 8px;
      span {
        color: var(--Platform-Grayscale-55, #838188);
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
      }
      svg {
        cursor: pointer;
        path {
          stroke: #838188;
        }
      }
      padding: 0.35rem 0.5rem;
      &:hover {
        span {
          color: #ee0b0b;
        }
        svg path {
          stroke: #ee0b0b;
        }
      }
      &.active {
        border-radius: 0.25rem;
        background: var(--Platform-System-Error-tint, #ffdada);
        span {
          color: #ee0b0b;
        }
        svg path {
          stroke: #ee0b0b;
        }
      }
    }
    .zoom-btn {
      padding: 0.5rem;
      background-color: var(--platform-grayscale-5, #f2f1f5);
      cursor: pointer;
    }
  }

  .active-boundary{
    position: absolute;
    top: 5px;
    z-index: 0;
    left: 8px;
    box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.10);
    svg path{
      stroke: #7856FF;
      stroke-width: 2px;
      // filter: drop-shadow(2px 0px 2px rgb(0 0 0 / 0.4));
    }
  }
}
