.keep-duplicate-actions {
  display: grid;
  grid-template-columns: 1fr;
  place-content: start;
  place-items: start;
  row-gap: 0.5rem;
  width: 100%;

  &__title {
    color: var(--platform-grayscale-40, #a2a1a8);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  &__button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;
    place-content: start;
    place-items: start;
    width: 100%;
  }

  &__primary-button {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    background: var(--platform-purple-basic, #7856ff);

    color: var(--platform-white, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    &:disabled {
      background: var(--platform-grayscale-10, #e5e4eb);
      color: var(--platform-grayscale-25, #cccad1);

      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  &__secondary-button {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    background: var(--platform-purple-tint, #f8f8fb);

    color: var(--platform-grayscale-90, #3c3a41);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
