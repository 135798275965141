.colaborator {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0 0 0 12px;
  .add-colab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    cursor: pointer;
    margin-left: -8px;
    z-index: 10;
    background: var(--platform-purple-basic, #7856ff);
    svg {
      margin: 0 !important;
      path {
        stroke: #fff !important;
      }
    }
    &:hover {
      background-color: #4d29db;
    }
    &.active {
      background-color: #d0c8f3;
      svg {
        path {
          stroke: #4d29db;
        }
      }
    }
  }
  .extra-colab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 31px;
    height: 31px;
    border: 1.5px solid #fff;
    border-radius: 100px;
    cursor: pointer;
    margin-left: -10px;
    z-index: 10;
    background: var(--platform-grayscale-10, #e5e4eb);
    color: #18161b;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  .member_avatar-letter {
    font-size: 13px;
  }
}
