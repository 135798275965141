.set-boundary-action-bar {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1000;
  column-gap: 10px;

  .edit-btn {
    border-radius: 6.25rem;
    background: var(--Platform-Purple-Tint, #f8f8fb);
    display: flex;
    padding: var(--number-spacing-xs, 0.75rem);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg path {
      stroke: #3c3a41;
    }
  }

  .success-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin: 0 15px 0 60px;
    span {
      color: var(--color-system-success-primary, #06b54c);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }
  }

  &__control {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    border-radius: 6.25rem;
    background: var(--Platform-Purple-Tint, #f8f8fb);
    padding: 0.5rem 1rem 0.5rem 1.5rem;
  }
  .pagecontrol_savebtn {
    padding: 0.3125rem 1.5rem;
    border-radius: 0.25rem;
    text-wrap: nowrap;
    white-space: nowrap;
    margin-left: 60px;
    &.crt {
      margin-left: 0px;
    }
  }
  .set-boundary-modal__input-container {
    margin: 0rem;
    align-items: center;
    .modal-input-label {
      width: auto;
      text-wrap: nowrap;
      white-space: nowrap;
      margin-right: 0.5rem;
      padding: 0;
      color: var(--Platform-Grayscale-55, #838188);
      font-family: 'Helvetica Neue';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }
    .modal-input-group {
      width: 8rem;
      &.select {
        width: 10.5rem;
      }
    }
    .input-wrapper input {
      height: 2rem;
      padding: 0px 10px;
      border: none;
      background: var(--Platform-White, #fff);
    }
    .modal-input-select {
      height: auto;
    }
  }
}
