.toc-tab {
  &-header {
    display: flex;
    padding: 1.5rem;
    justify-content: space-between;
    align-items: center;
    &-title {
      color: var(--Platform-Grayscale-90, #3c3a41);
      font-family: 'Helvetica Neue';
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
    }
    &-left,
    &-right {
      display: flex;
      flex-direction: row;
      column-gap: 25px;
      align-items: center;
    }
    &-right {
      .sort-itm {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
        span {
          color: var(--Platform-Grayscale-55, #838188);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem;
        }
        &-btn {
          width: 100px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          column-gap: 10px;
          padding: var(--number-spacing-3xs, 0.25rem) var(--number-spacing-xs, 0.75rem);
          color: var(--Platform-Grayscale-90, #3c3a41) !important;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.25rem;
          border-radius: var(--number-radius-xxs, 0.25rem);
          border: 1px solid var(--Platform-Grayscale-10, #e5e4eb) !important;
          background: var(--color-grayscale-10, #fff) !important;
        }
      }
      .crt-sectn {
        display: flex;
        flex-direction: row;
        column-gap: 0.48rem;
        padding: 0.25rem 0.86rem;
        align-items: center;
        svg path {
          stroke: #3c3a41;
        }
      }
    }
  }
  &-content {
    &-container {
      padding: 0 1.5rem;
    }
    &-item {
      display: grid;
      grid-template-columns: 3fr 1fr;
      border-bottom: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
      padding: var(--number-spacing-xxs, 0.5rem);
      margin-top: 4px;
      .page-name {
        overflow: hidden;
        color: var(--Platform-Grayscale-75, #56555c);
        text-overflow: ellipsis;
        font-family: 'Helvetica Neue';
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem; /* 150% */
        cursor: pointer;
      }
    }
    &-sf {
      &-head {
        padding: 0 1.5rem;
      }
      .sf-title {
        color: var(--Platform-Grayscale-75, #56555c);
        font-family: 'Helvetica Neue';
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
        padding: var(--number-spacing-xxs, 0.5rem);
        margin-top: 4px;
      }
      .sf-table-item {
        display: grid;
        grid-template-columns: 6fr 4fr 3fr 1fr;
        .action-cell {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 10px;
          //   position: relative;
          svg {
            cursor: pointer;
            circle {
              fill: #838188;
              stroke: #838188;
            }
            path {
              stroke: #838188;
            }
          }
        }
      }
      .sf-thead {
        padding: var(--number-spacing-xxs, 0.5rem);
        color: var(--Platform-Grayscale-75, #56555c);
        font-family: 'Helvetica Neue';
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1rem; /* 133.333% */
        border-bottom: 2px solid var(--Platform-Grayscale-10, #e5e4eb);
      }
      .sf-section {
        margin: 20px 0 0;
      }
      .sf-section-header {
        color: var(--Platform-Grayscale-55, #838188);
        font-family: 'Helvetica Neue';
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem; /* 150% */
        padding: var(--number-spacing-3xs, 0.25rem) var(--number-spacing-xxs, 0.5rem);
        background: var(--Platform-Grayscale-Hover, #fafafa);
      }
      .sf-section-row {
        overflow: hidden;
        color: var(--Platform-Grayscale-90, #3c3a41);
        text-overflow: ellipsis;
        font-family: 'Helvetica Neue';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 150% */
        padding: 1rem 0.5rem 1rem 0.5rem;
        border-bottom: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
        background-color: #ffffff;
        min-height: 57px;
        width: 100%;
        &:hover {
          background: var(--Platform-Grayscale-Hover, #fafafa);
        }

        &.active {
          border-bottom: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
          background: var(--Platform-Purple-Ghost, #f1eefb);
        }
        .first-cell {
          padding-left: 1rem;
        }
        span {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@import './Section/style';
@import './Row/style';
@import './ArchiveRow/style';
@import './ChangeNameModal/style';
@import './CreateSectionModal/style';
