.progress-bar {
  display: flex;
  height: 12px;
  align-items: center;
  border-radius: 100px;
  background: var(--color-grayscale-40, #e5e7e7);
  width: 100%;
  &__inner {
    height: 12px;
    border-radius: 100px;
    background: var(--color-system-success-primary, #06b54c);
  }

  @mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
    background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  }
  
  @-webkit-keyframes progress-bar-stripes {
    from  { background-position: 40px 0; }
    to    { background-position: 0 0; }
  }
  
  // Spec and IE10+
  @keyframes progress-bar-stripes {
    from  { background-position: 40px 0; }
    to    { background-position: 0 0; }
  }
  
  @mixin animation($animation) {
    -webkit-animation: $animation;
         -o-animation: $animation;
            animation: $animation;
  }
  
  &.active &__inner, &__inner.active {
    @include animation(progress-bar-stripes 2s linear infinite);
  }
  
  .progress-striped &__inner, .progress-bar-striped {
    @include gradient-striped;
    background-size: 40px 40px;
  }

}
