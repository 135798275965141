.download-pdf-btn {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10px;
  color: var(--Platform-Grayscale-90, #3c3a41) !important;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  border-radius: var(--number-radius-xxs, 0.25rem);
  border: 1px solid var(--Platform-Grayscale-10, #e5e4eb) !important;
  background: var(--color-grayscale-10, #fff) !important;
}