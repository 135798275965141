.comp-info {
  color: #3c3a41;
  font-size: 0.92rem;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 30px;
    margin-right: 10px;
  }
}
