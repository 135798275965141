.system-message {
  .msg {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: center;
  }
  &.success {
    background: #06b54c !important;
    color: #ffffff !important;
    box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
    .msg {
      svg {
        margin: 0;
        margin-right: 10px;
        path {
          stroke: #ffffff;
        }
      }
    }
  }
  &.warning {
    background: #ee0b0b !important;
    color: #ffffff !important;
    box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
    .msg {
      svg {
        margin: 0;
        margin-right: 15px;
        path {
          stroke: #ffffff;
        }
      }
    }
  }
  &.info {
    background: #fff !important;
    color: #3c3a41 !important;
    box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
    border: 1px solid #e5e4eb;
    .msg {
      svg {
        margin: 0;
        margin-left: 15px;
        path {
          stroke: #3c3a41;
        }
      }
    }
  }
}
.timer-toast {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  a {
    font-weight: 500;
    text-decoration: underline;
  }
}
