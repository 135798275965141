.coverpage-logoUpload .upload-box {
  justify-content: space-between;
  border: 1px dashed var(--Platform-Grayscale-25, #cccad1);
  &-label {
    color: var(--Platform-Grayscale-90, #3c3a41);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    margin-bottom: 0.5rem;
  }
  .desc {
    display: flex;
    flex-direction: column;
    margin: 0;
    color: #18161b;
    svg path {
      stroke: #18161b;
    }
  }
  .input-btn {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    color: var(--Platform-Grayscale-90, #3c3a41);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    background: var(--Platform-Purple-Tint, #f8f8fb);
    &:hover {
      background-color: #e5e4eb;
    }
  }
  .divider {
    color: var(--Platform-Grayscale-40, #a2a1a8);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
  }
}
