.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    // border: 1px solid #525F70;
    border: 1px solid #c8cbca;
    border-radius: 50%;
    &.small {
      height: 16px;
      width: 16px;
    }
  }
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
}

/* On mouse-over, add a grey background color */
//   .radio-container:hover input ~ .checkmark {
//     background-color: #ccc;
//   }

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #4c35de;
  border: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ffffff;
}
.radio-container .checkmark.small:after {
  top: 4px;
  left: 4px;
}
