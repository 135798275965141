.template-setting-modal {
  width: 69.5625rem !important;
  @media screen and (max-width: 69.5625rem) {
    width: 95vw !important;
  }
  min-width: 600px;
  padding: 0.75rem 0rem 1rem 0rem;
  // max-height: 45rem;
  height: $template-setting-height;
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    h3 {
      color: var(--Platform-Black, #18161b);
      font-family: 'Helvetica Neue';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 144.444% */
    }
  }
  &-content {
    display: flex;
    flex-direction: row;
    height: calc($template-setting-height - 50px);
    &__right {
      width: 100%;
      .template-setting-modal-tabs {
        padding: 0rem 2rem 0rem 0rem;
        .create-template {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: calc(80vh - 50px - 86px);
          padding-top: 5rem;
          &__title {
            color: var(--Platform-Grayscale-90, #3c3a41);
            text-align: center;
            font-family: 'Helvetica Neue';
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 1.5rem;
          }
          &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 25rem;
            padding: 0rem 1rem 1rem 1rem;
            gap: 0.5rem;
            .input-with-label {
              width: 100%;
              margin-bottom: 5px;
              .inpt-label {
                color: var(--color-grayscale-70, #7e8180);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 146%; /* 1.2775rem */
                letter-spacing: -0.00875rem;
              }
              .inpt-err {
                font-size: 0.75rem;
              }
              input {
                font-size: 0.875rem;
                &:focus,
                &:active {
                  border: 1px solid #f0f1f1;
                }
              }
            }
            .button {
              width: 100%;
              padding: 0.25rem 1rem;
              font-size: 0.875rem;
              height: 2rem;
            }
          }
        }
        .tabs {
          padding-bottom: 0;
          padding-left: 2rem;
        }
        .tabs-item {
          padding-top: 0px;
          &:first-of-type {
            padding-top: 0px;
          }
        }
      }
    }
    &__left {
      padding: 0rem 1rem 1rem 1rem;
      width: 11rem;
      border-right: 1px solid #e5e4eb;
      .info {
        color: var(--Platform-Grayscale-55, #838188);
        font-family: 'Helvetica Neue';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
      }
      .new-temp {
        display: flex;
        height: 32px;
        align-items: center;
        color: var(--Platform-Grayscale-90, #3c3a41);
        font-family: 'Helvetica Neue';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 142.857% */
        text-wrap: nowrap;
        padding: 0.25rem 1rem;
        gap: 8px;
        svg {
          path {
            stroke: #3c3a41;
          }
        }
      }
      .template-list {
        display: flex;
        flex-direction: column;
        gap: var(--number-spacing-m, 8px);
        margin-top: 8px;
        height: calc(100% - 28px);
        overflow: auto;
        &__item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 6px;
          color: var(--Platform-Grayscale-90, #3c3a41);
          font-family: 'Helvetica Neue';
          font-size: 16px;
          font-style: normal;
          line-height: 24px; /* 150% */
          padding: var(--number-spacing-xxs, 6px);
          &.cursor {
            cursor: pointer;
          }
          &.active {
            .content-editable {
              color: var(--Platform-Purple-Dark, #4d29db);
              font-weight: 500;
              padding: 1px 8px 1px 4px;
              caret-shape: block;
            }
          }
          svg {
            cursor: pointer;
            flex-shrink: 0;
            path {
              stroke: #838188;
            }
          }
        }
      }
    }
  }
  &-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
    .del-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      color: #ee0b0b;
      cursor: pointer;
      svg path {
        stroke: #ee0b0b;
      }
      &.disabled {
        // pointer-events: none;
        cursor: not-allowed;
        color: #cccad1 !important;
        svg path {
          stroke: #cccad1;
        }
      }
    }
    padding: 24px;
    .action-btn {
      display: flex;
      flex-direction: row;
      gap: 8px;
      button {
        padding: 8px 32px;
      }
    }
    .disabled {
    }
  }
}
