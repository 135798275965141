.page_loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  img.loader {
    width: 120px;
    height: 120px;
  }
}
