.select-wrapper {
  select {
    color: var(--platform-black, #18161b);
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    background: none;
    width: 36px;
  }
}
