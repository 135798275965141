@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply py-1 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }

  .btn-secondary {
    @apply py-1 px-4 bg-slate-100 text-slate-700 font-semibold rounded shadow-md hover:bg-slate-300 focus:outline-none focus:ring-2 focus:ring-slate-200 focus:ring-opacity-75;
  }

  .txt-primary {
    @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
  }
}

:not(.touch)::-webkit-scrollbar,
:not(.touch) ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  -webkit-appearance: none;
  border-radius: 100px;
}
:not(.touch)::-webkit-scrollbar-thumb,
:not(.touch)::-webkit-scrollbar-thumb:vertical {
  background-color: #707070;
  border: none;
  border-radius: 100px;
}
:not(.touch)::-webkit-scrollbar-track,
:not(.touch) ::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 100px;
}
:not(.touch)::-moz-selection,
:not(.touch) ::-moz-selection {
  background: #707070;
}
:not(.touch)::selection,
:not(.touch) ::selection {
  background: #707070;
}