.column-view__page-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.column-view__page-type-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  //   position: relative;
  &.error {
    .column-view__page-type {
      color: var(--color-system-error-primary, #ee0b0b) !important;
    }
    svg path {
      stroke: var(--color-system-error-primary, #ee0b0b);
    }
  }
}
.column-view__page-type {
  color: var(--Platform-Grayscale-90, #3c3a41);
  font-family: 'Helvetica Neue';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
}
.column-view__page-range {
  color: #838188;
  font-family: 'Helvetica Neue';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
.column-view__page-detail__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -22px;
}
.column-view__page-type-name__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  color: var(--Platform-Grayscale-55, #838188);
  font-family: 'Helvetica Neue';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
}
.column-view__page-type__inner {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.column-view__page-range__inner {
  color: var(--Platform-Grayscale-55, #838188);
  font-family: 'Helvetica Neue';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
.page-card__cover {
  position: relative;
}
.page-card__veil {
  position: absolute;
  opacity: 0.5;
  background: var(--Platform-Grayscale-10, #e5e4eb);
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 100;
  border-radius: 0.5rem;
}
.column-view__page-type-dd {
  position: relative;
  svg {
    cursor: pointer;
  }
}
