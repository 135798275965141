.service-setup__body {
  display: flex;
  flex-direction: row;
  height: calc(100vh - $dash-menu-bar-height);
}

.coming-soon {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.page-backnav {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  span {
    color: #838188;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    margin-left: 5px;
  }

  &:hover {
    svg path {
      stroke: #4d29db;
    }

    span {
      color: #4d29db;
    }
  }
}

.service-setup__sidebar {
  padding: 2rem;
  border-right: 1px solid #f0f1f1;
  max-width: 360px;
  display: flex;
  flex-direction: column;

  .service-feat {
    .sname {
      font-size: 1.1rem;
      color: #0e1010;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
    }

    .desc {
      font-size: 0.8rem;
      color: #8a8b8b;
      padding: 10px 0;
    }
  }
}

.video-rating {
  margin-top: auto;

  img {
    cursor: pointer;
  }

  .title {
    color: #7e8180;
    font-size: 0.8rem;
    margin-bottom: 20px;
  }

  .rate {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .rateg {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      gap: 0.75rem;

      .rateno {
        color: #7e8180;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 500;
        margin-left: 5px;
      }
    }

    .review {
      color: #8a8b8b;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 500;
    }
  }
}

.service-setup__content {
  padding: 2.2rem;
  width: 100%;
}

.detail-box {
  // height: calc(100% - 81px);
  // height: calc(100% - 65px);
  // overflow-y: auto;

  // @media screen and (max-height: 926px) and (min-height: 600px) {
  //   height: 704px;
  // }

  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #f8f8f8;
  padding: 30px 40px;

  .footer {
    margin-top: auto;
    padding: 15px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .prev {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 30px;
      color: #0e1010;
      text-align: center;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 500;
      cursor: pointer;

      svg {
        margin-right: 6px;

        path {
          stroke: #0e1010;
        }
      }
    }

    .uploading-process {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 40px;

      &__text {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        span {
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          color: var(--color-grayscale-80, #4a4c4c);
        }
      }
    }
  }
}

.file-det-list {
  overflow: auto;

  &__sketch {
    border-radius: 8px;
    margin-bottom: 6px;
  }
}

.define-job {
  overflow-y: auto;
  .htitle {
    color: #0e1010;
    font-size: 1.1rem;
    // font-size: 1.05rem;
    font-style: normal;
    font-weight: 500;
  }

  .stype {
    margin-top: 25px;

    &_label {
      color: #7e8180;
      font-size: 0.85rem;
      font-style: normal;
      font-weight: 400;
    }

    &_val {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      margin-bottom: 28px;
    }

    .radio-container {
      margin-right: 60px;
      padding-left: 30px !important;
      margin-bottom: 0px;
    }
  }

  .dtype {
    margin-top: 28px;

    &_label {
      color: #7e8180;
      font-size: 0.85rem;
      font-style: normal;
      font-weight: 400;
    }

    &_val {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      color: #0e1010;
      font-size: 0.85rem;
      font-style: normal;
      font-weight: 400;

      svg {
        margin-right: 10px;
      }
    }
  }

  .input-with-label {
    margin: 25px 0;
  }
}

.confirm-job {
  .htitle {
    color: #0e1010;
    // font-size: 1.05rem;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
  }

  .collate {
    padding: 16px 20px;
    border-radius: 8px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 40px 0;

    .item {
      .type {
        color: #898989;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .val {
        color: #0e1010;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .terms {
    display: flex;
    flex-direction: row;

    .conditn {
      color: #0e1010;
      font-size: 0.86rem;
      font-style: normal;
      font-weight: 400;
      padding: 2px 10px;

      p {
        line-height: 20px;
        padding: 5px 0;
      }
    }
  }
}

.confirm-tsc-modal,
.confirm-bac-modal {
  overflow: hidden;
  position: relative;
  box-shadow: none !important;

  .modalContent {
    overflow-y: scroll;
    padding-bottom: 24px;
  }
}
