textarea {
  width: 100%;
  height: 160px;
  padding: 14px;
  border: 1px solid #f0f1f1;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 0.87rem;
  resize: none;
  outline: none;
  &::placeholder {
    color: #a7aaa9;
  }
  &:focus,
  &:active {
    border: 1px solid #0e1010;
  }
  letter-spacing: normal;
}
