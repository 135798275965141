.file-review-center-view-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //   padding: 10px 0;
  &__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-size: 14px;
      color: var(--platform-grayscale-55, #838188);
      font-weight: 500;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;
    }
    .icon {
      cursor: pointer;
      path {
        stroke: #838188;
      }
    }
  }
  &__status {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 60px;
    .text-item {
      padding: var(--number-spacing-xxs, 0.5rem) var(--number-spacing-xs, 0.75rem);
      border-radius: 4px;
      font-size: 12px;
      column-gap: 12px;
      margin-right: 16px;
    }
    &-done {
      background: rgba(214, 250, 228, 0.5);
      color: #06b54c;
    }
    &-incomplete {
      background: rgba(255, 243, 203, 0.5);
      color: #e1b000;
    }
    &-missing {
      background: var(--Platform-Grayscale-5, #f2f1f5);
      color: var(--Platform-Grayscale-75, #56555c);
    }
  }
  &__boundary {
    color: var(--Platform-White, var(--color-grayscale-10, #fff));
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    border-radius: 0.25rem;
    background: var(--Platform-Purple-Basic, #7856ff);
    padding: 0.5rem 1rem;
    svg {
      path,
      rect {
        stroke: var(--Platform-White, var(--color-grayscale-10, #fff));
      }
    }
  }
}
