.sf-section-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .drag-icon {
    margin-right: 8px;
    visibility: hidden;
  }
  &:hover {
    .drag-icon {
      visibility: visible;
    }
  }
  .action-cell {
    .menu-item {
      color: var(--Platform-Purple-Dark, #4d29db);
      /* Platform/Medium/S */
      font-family: 'Helvetica Neue';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }
  }
}

.toc-section-menu {
  display: flex;
  flex-direction: column;
  padding: 0.625rem var(--number-spacing-s, 1rem);
  align-items: flex-start;
  gap: var(--number-spacing-s, 0.8rem);
  width: 14.5rem;
  &-head {
    color: var(--Platform-Grayscale-90, #3c3a41);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
  }
  &-item-container {
    display: flex;
    flex-direction: column;
    padding: 0rem;
    align-items: flex-start;
    gap: var(--number-spacing-s, 0.8rem);
    width: 100%;
  }
  &-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    span {
      overflow: hidden;
      color: var(--Platform-Grayscale-90, #3c3a41);
      text-overflow: ellipsis;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
      &:hover {
        color: var(--Platform-Purple-Dark, #4d29db);
      }
    }
    &.active span:hover {
      color: var(--Platform-Grayscale-90, #3c3a41) !important;
    }
    svg path {
      stroke: var(--Platform-Grayscale-90, #3c3a41);
    }
  }
}
