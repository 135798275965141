.unsaved-change-modal {
  width: 28rem;
  padding: 1rem;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--Platform-Grayscale-90, #3c3a41);
    font-family: 'Helvetica Neue';
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
    margin-bottom: 16px;
    svg {
      cursor: pointer;
    }
  }
  .action-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    &__left {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
    .button {
      width: 90px;
      padding: 0.5rem 0;
    }
  }
}
