.coverpage-tab {
  width: 100%;
  padding: var(--number-spacing-m, 1.5rem) var(--number-spacing-xxl, 3rem) 0
    var(--number-spacing-xxl, 3rem);
  &-title {
    color: var(--Platform-Grayscale-90, #3c3a41);
    font-family: 'Helvetica Neue';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  }
  &-content {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(360px, 1fr));
    height: calc(100vh - 149px);
    // overflow-y: auto;
    &-item {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .textfield__input-container {
        height: auto;
        padding: 0.5625rem 1rem;
        border: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
        .textfield__input {
          color: var(--Platform-Black, #18161b);
        }
      }
      .react-date-picker__wrapper {
        padding: 0.5625rem 1rem;
        border: 1px solid var(--Platform-Grayscale-10, #e5e4eb) !important;
        .react-date-picker__button {
          padding: 0px 6px;
        }
      }

      .file-detail {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &-name {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 10px;
          color: var(--Platform-Grayscale-90, #3c3a41);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.25rem;
        }
        svg.clx {
          cursor: pointer;
        }
      }

      .inc-logo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        &-label {
          color: var(--Platform-Grayscale-75, #56555c);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem; /* 142.857% */
        }
      }
      .save-btn {
        color: var(--Platform-White, var(--color-grayscale-10, #fff));
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
        margin-top: 1rem;
        padding: 0.5rem 2rem;
      }
    }
  }
}

@import './LogoUpload/style';
@import './CoverPageCard/style';
