.review-top-view {
  border-bottom: 1px solid var(--platform-grayscale-10, #e5e4eb);
  .icon-btn.go-back {
    background-color: #fff;
    cursor: pointer;
  }
  .job-view {
    display: flex;
    flex-direction: row;
    margin:0 .5rem;
    align-items: center;
  }
  .nav-info {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    .nav-title {
      color: var(--platform-black, #18161b);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
    .nav-access {
      display: flex;
      flex-direction: row;
      align-items: center;
      &-dt {
        color: var(--platform-black, #18161b);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
      }
      &-usr {
        overflow: hidden;
        color: var(--platform-grayscale-55, #838188);
        text-overflow: ellipsis;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}

.select-column-no {
  margin-right: 1rem;
}

.select-column-no,
.collabo,
.select-workflow-status {
  color: var(--platform-black, #18161b);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  span {
    margin-right: 14px;
  }
}

.review-top-view {
  .summary-menus {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;
      color: var(--Platform-Grayscale-90, #3c3a41);
      font-family: 'Helvetica Neue';
      font-size: .875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      padding: 0.2rem 0.75rem;
      white-space: nowrap;
      border-radius: var(--number-radius-xxs, 0.25rem);
      &.active,&:hover.active {
        background: var(--Platform-Grayscale-25, #cccad1);
      }
      &:hover{
        background: var(--Platform-Grayscale-10, #e5e4eb);
      }
    }
  }
  .download-btn {
    padding: 0.25rem 1.6875rem;
  }
  .download-btn_reviewer{
    padding: 0.34rem 1rem;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    background: var(--Platform-Grayscale-90, linear-gradient(0deg, rgba(0, 0, 0, 0.20)0%, rgba(0, 0, 0, 0.20)100%), #3C3A41);
    &:disabled{
      background: var(--Platform-Grayscale-90, linear-gradient(0deg, rgba(0, 0, 0, 0.20)0%, rgba(0, 0, 0, 0.20)100%), #3C3A41);
      color: #ffffff !important;
    }
  }
}
