.service__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 15px;
  row-gap: 30px;
}
.newtime {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  .title {
    color: #0e1010;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .subtitle {
    color: #7e8180;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
}
