.edit-boundary-modal {
  display: flex;
  width: 33rem;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  gap: var(1rem);
  &__title {
    color: var(--platform-black, #18161b);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  }
  &__subtitle {
    color: var(--platform-grayscale-75, #56555c);
    font-size: 0.82rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }
  &__input-container {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    gap: 1rem;
    .textfield__label p, .datefield_label{
      color: #56555C;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
    }
    .textfield__input-container{
      padding: 0.5625rem 1rem;
      border-color: inherit;
      height: auto;
      .input{
        border-color: #E5E4EB;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
      }
    }
    .react-date-picker__wrapper{
      padding: 0.35rem 1rem;
      border: 1px solid #E5E4EB !important;
      height: auto;
    }
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 0.5rem;
    padding-top: 0.4rem;
    .button {
      padding: 0.5rem 2rem;
    }
  }
}
