.instruction-and-job-information-accordion {
  &__header {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: flex-start;
  }
  &__title {
    color: var(--platform-grayscale-90, #3c3a41);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    justify-items: flex-end;
    column-gap: 0.5rem;
    color: var(--platform-grayscale-55, #838188);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    &-icon {
      &--rotate-180 {
        transform: rotate(180deg);
      }

      &--rotate-0 {
        transform: rotate(0deg);
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: start;
    width: 100%;

    &--open {
      height: 100%;
    }

    &--closed {
      height: 44px;
      overflow: hidden;
    }
  }
  &__description {
    color: var(--platform-grayscale-40, #a2a1a8);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
