.switch-slider {
  position: relative;
  display: inline-block;
  width: 40px;
  flex: 0 0 40px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #a2a1a8;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 3px;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #4d29db;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #4d29db;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
    background-color: #ffffff;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  &.disabled {
    .slider {
      background-color: #f8f8fb;
      pointer-events: none;
    }
    input:checked + .slider:before,
    .slider:before {
      background-color: #e5e4eb;
    }
    input:checked + .slider {
      background-color: #f8f8fb;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px #f8f8fb;
    }
  }
}
