.create-section-modal {
  width: 23rem;
  padding: 16px 24px;
  .modalContent {
    display: flex;
    flex-direction: column;
    gap: var(--number-spacing-s, 1rem);
    .create-section-header {
      color: var(--Platform-Black, #18161b);
      font-family: 'Helvetica Neue';
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem; /* 150% */
    }
  }
  input {
    &:focus,
    &:active {
      border: 1px solid #f0f1f1;
    }
  }
  .error-msg {
    font-size: 12px;
    color: #ee0b0b;
  }
  .action-btns {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    button {
      width: 100%;
    }
  }
}
