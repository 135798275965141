.duplicate-page-removed-list-container {
  width: 100%;
  .duplicate-page-list-item {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: start;
    width: 100%;
    padding: 0.25rem 0.5rem;

    &:hover,
    &.active {
      background: var(--platform-purple-tint, #f8f8fb);
      border-radius: 0.25rem;
    }

    &__label-container {
      display: grid;
      grid-auto-flow: column;
      width: 100%;
      column-gap: 0.5rem;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
      justify-items: flex-start;
      svg circle {
        stroke: #ee0b0b;
        fill: #ee0b0b;
      }
    }

    &__label {
      color: var(--platform-grayscale-90, #3c3a41);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
    }

    &__action {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 16px;
      svg path {
        stroke: #838188;
      }
    }
  }
}

.duplicate-page-removed-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  margin-left: 1rem;
  &:hover {
    p {
      color: var(--Platform-Purple-Dark, #4d29db);
    }
    svg path {
      stroke: var(--Platform-Purple-Dark, #4d29db);
    }
  }
  p {
    color: var(--Platform-Grayscale-90, #3c3a41);
    font-family: 'Helvetica Neue';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
  &__arrow-icon {
    &--rotate-180 {
      transform: rotate(180deg);
    }

    &--rotate-0 {
      transform: rotate(0deg);
    }
  }
}

.duplicate-page-list-item-wrapper {
  width: 100%;
  margin-top: 1rem;
  max-height: 450px;
  overflow-y: auto;
}
