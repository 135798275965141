.file-det {
  border-radius: 4px;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin: 10px 0;
  .desc {
    .file-n {
      color: #4a4c4c;
      font-style: normal;
      font-weight: 500;
      font-size: 0.95rem;
      line-height: 25px;
    }
    .file-m {
      color: #7e8180;
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 25px;
    }
  }
  .desright {
    display: flex;
    flex-direction: row;
    align-items: center;
    .msg {
      color: var(--color-system-error-primary, #ee0b0b);
      font-weight: 400;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      margin-right: 20px;
      max-width: 278px;
      letter-spacing: -0.0175rem;
      svg {
        margin-right: 5px;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  svg {
    cursor: pointer;
    &:hover {
      path {
        stroke: #ee0b0b;
      }
    }
  }
}
