.button {
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 4px;
  font-size: 0.9rem;
  outline: none;

  &.small {
    padding: 8px 20px;
    font-size: 0.6rem;
  }

  &.contained {
    background: #7856ff;
    color: #ffffff;
    border: none;
  }
  &.outlined {
    background: #ffffff;
    color: #7856ff;
    border: 1px solid #7856ff;
  }

  &.cta {
    background-color: #7856ff;
    color: #ffffff;
    &:hover {
      background-color: #4d29db;
    }
    &:active {
      background-color: #4d29db;
      color: #d0c8f3;
    }
    &.outlined {
      background: #ffffff;
      color: #7856ff;
      border: 1px solid #7856ff;
    }
  }
  &.negative {
    background-color: #ffdada;
    color: #ee0b0b;
    &:hover {
      background-color: rgba(240, 12, 12, 0.4);
      color: #ee0b0b;
    }
    &:active {
      background-color: rgba(240, 12, 12, 0.4);
      color: #ffffff;
    }
  }
  &.secondary {
    background-color: #0e1010;
    color: #ffffff;
    &:hover {
      background-color: #4a4c4c;
      color: #ffffff;
    }
    &:active {
      background-color: #4a4c4c;
      color: #ebe9f6;
    }
    &.outlined {
      background: #ffffff;
      color: #0e1010;
      border: 1px solid #0e1010;
    }
  }
  &.tint {
    background-color: #f8f8fb;
    color: #4a4c4c;

    &:hover {
      background-color: #e5e4eb;
      color: #4a4c4c;
    }
    &:active {
      background-color: #e5e4eb;
      color: #18161b;
    }
  }

  &.ghost {
    background-color: #e7f2f2;
    color: #0e1010;
    &:hover {
      background-color: #dce7e7;
      color: #0e1010;
    }
    &:active {
      background-color: #dce7e7;
      color: #4a4c4c;
    }
  }

  &.disable {
    background-color: #f0f1f1;
    color: #a7aaa9;
    pointer-events: none;
  }

  &.normal {
    width: auto;
  }
  &.full {
    width: 100%;
  }

  &.cancelBtn {
    width: 100%;
    margin-top: 20px;
    border: none;
    color: #2c3e50;
    background: #f8f8fb;
    transition: all 0.25s ease;
  }

  &.cancelBtn:hover {
    box-shadow: none;
    transform: none;
    background: whitesmoke;
  }

  &.saveBtn {
    width: 100%;
    background: #7856ff;
    color: #ffffff;
  }
}
