.unactioned-status-right-view {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  place-content: start;
  place-items: start;
  width: 100%;
  height: 100%;

  &__top-container {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: start;
    row-gap: 0.625rem;
    padding: 1rem;
    width: 100%;
    border-bottom: 1px solid var(--platform-grayscale-25, #cccad1);
    .exit-btn {
      margin-top: 5px;
      padding: 0.5rem 2rem;
      &:hover {
        background-color: #4d29db;
      }
      &:disabled {
        background-color: var(--Platform-Grayscale-5, #f2f1f5) !important;
        color: var(--Platform-Grayscale-25, #cccad1) !important;
      }
    }
  }

  &__center-container {
    // display: grid;
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    // place-content: start;
    // place-items: start;
    row-gap: 1rem;
    padding: 1rem;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid var(--platform-grayscale-25, #cccad1);

    &__form {
      &__save-button {
        display: flex;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 4px;
        background: var(--platform-purple-basic, #7856ff);

        color: var(--platform-white, #fff);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        &:disabled {
          background: var(--platform-grayscale-10, #e5e4eb);
          color: var(--platform-grayscale-25, #cccad1);

          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    &__change-container {
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: 0.5rem;
      width: 100%;

      &__change-button {
        display: flex;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 4px;
        background: var(--platform-purple-tint, #f8f8fb);

        color: var(--platform-grayscale-90, #3c3a41);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &__toast-container {
      display: grid;
      grid-auto-flow: column;
      column-gap: 1px;
      align-items: center;
      align-content: center;
      justify-items: flex-end;
      justify-content: flex-end;

      &__text {
        color: var(--color-system-success-primary, #06b54c);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: -0.13px;
      }
    }
  }

  &__bottom-container {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: start;
    row-gap: 0.625rem;
    padding: 1rem;
    width: 100%;
  }
}
