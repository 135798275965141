.set-boundary-modal {
  display: flex;
  width: 33rem;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  gap: var(1rem);
  &__title {
    color: var(--platform-black, #18161b);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  }
  &__subtitle {
    color: var(--platform-grayscale-75, #56555c);
    font-size: 0.82rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }
  &__input-container {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    .modal-input-label {
      width: 150px;
      color: var(--platform-grayscale-75, #56555c);
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      padding-top: 0.5rem;
    }
    .modal-input-group {
      width: 100%;
      &__info {
        color: #989898;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
        margin: 0.5rem 0 0;
      }
    }
    .modal-input-select {
      width: 100%;
      height: 2.5rem;
    }
    .input-wrapper {
      width: 100%;
      input {
        border-color: var(--platform-grayscale-10, #e5e4eb);
        height: 2.5rem;
        &:hover {
          border-color: #56555c;
        }
      }
    }
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 0.5rem;
    padding-top: 0.4rem;
    .button {
      padding: 0.5rem 2rem;
    }
  }
}
