.content-filter {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  column-gap: 30px;

  .btn-it {
    cursor: pointer;
    &.sel {
      margin-left: 14px;
    }
    svg {
      margin-right: 10px;
      &.upload,
      &.check-unsel {
        path,
        rect {
          stroke: #3c3a41;
        }
      }
    }
    &:hover {
      color: #4d29db;
      svg {
        rect,
        path {
          stroke: #4d29db;
        }
        &.check-sel path {
          stroke: #ffffff;
        }
      }
    }
  }
}
