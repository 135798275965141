.taskcard {
  width: 100%;
  &__header {
    background: #7856ff;
    padding: 0.5rem 1rem 0.75rem 1rem;
    border-radius: 0.38rem 0.38rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--number-spacing-xxs, 0.5rem);
  }
  &__title {
    color: var(--color-grayscale-10, #fff);
    margin-top: 4px;
    font-family: 'Helvetica Neue';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
  }
  &__id {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    column-gap: 0.5rem;
    .vertical-line {
      border-color: rgba(207, 200, 243, 0.5);
    }
    .member_avatar-letter {
      font-size: 0.5625rem;
      border-width: 1.5px;
      border-color: #ffffff;
    }
    &__name {
      color: var(--color-grayscale-10, #fff);
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 500;
      line-height: 0.875rem;
    }

    &__subtitle {
      color: var(--platform-grayscale-75, #56555c);
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
    }

    &__timestamp {
      color: #cfc8f3;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.875rem;
    }
  }
  &__content {
    padding: 1rem;
    background-color: #f8f8fb;
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    border-radius: 0 0 0.38rem 0.38rem;
    .exit-btn {
      line-height: 1.5rem;
      padding: 0.5rem;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
    }
  }
}

.undone-ai-suggestion {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .text {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--Platform-Grayscale-90, #3c3a41);
    font-family: 'Helvetica Neue';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    svg {
      margin-right: 10px;
      path {
        stroke: #3c3a41;
      }
    }
  }
  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #7856ff;
    font-family: 'Helvetica Neue';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    svg {
      margin-left: 10px;
      path {
        stroke: #7856ff;
      }
    }
  }
}
