.job-prop__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  .txt {
    color: #838188;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    margin-right: 14px;
  }
  .val {
    color: #18161b;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 6px;
      path {
        stroke: #18161b;
      }
    }
  }
}
