.textfield-with-suggestion {
  position: relative;
  &__options {
    position: absolute;
    // top: 20px;
    width: 150px;
    border-radius: 0.25rem;
    border: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
    background: var(--Platform-White, #fff);
    box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
    z-index: 100;
    font-size: 0.875rem;
    max-height: 200px;
    overflow: auto;
    p {
      padding: 0.25rem 1rem 0.25rem 0.75rem;
      &:hover {
        color: var(--Platform-Purple-Dark, #4d29db);
        background: var(--Platform-Purple-Tint, #f8f8fb);
      }
    }
  }
}
