.page-card-tags {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 6px;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-bottom: 5px;
  &_item {
    max-width: 192px;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    border-radius: 2px;
    background: #d6f3ff;
    padding: 2px 12px;
  }
}
