@import './WorkflowSelectInput/style';
@import './PDFGenerateAndDownloadButton/style';

.review-top-view {
  border-bottom: 1px solid var(--platform-grayscale-10, #e5e4eb);
  //   .rightbar, .leftbar{
  //     width: 100%;
  //   }
  //   .rightbar{
  //     justify-content: space-between;
  //   }
  .icon-btn.go-back {
    background-color: #fff;
    cursor: pointer;
  }
  .nav-info {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    .nav-title {
      color: var(--platform-black, #18161b);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
    .nav-access {
      display: flex;
      flex-direction: row;
      align-items: center;
      &-dt {
        color: var(--platform-black, #18161b);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
      }
      &-usr {
        overflow: hidden;
        color: var(--platform-grayscale-55, #838188);
        text-overflow: ellipsis;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}

.select-column-no {
  margin-right: 24px;
}

.select-column-no,
.collabo,
.select-workflow-status {
  color: var(--platform-black, #18161b);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  span {
    margin-right: 14px;
  }
}
