.sf-section-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .drag-icon {
    margin-right: 8px;
    visibility: hidden;
  }
  &:hover {
    .drag-icon {
      visibility: visible;
    }
  }
  .sf-section-header {
    width: 100%;
  }
}
