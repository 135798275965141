.job-page__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-top: 1px solid #e5e4eb;
  padding: 0px 50px;
  height: $dash-footer-height;
  font-size: 14px;
  position: relative;
}
