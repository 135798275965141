.column-view {
  padding: 1rem 2.6rem 0 2.6rem;
  width: 100%;
  height: 100%;
  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 10px 0 10px 0;
  }
  &__page {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding-bottom: 220px;
    // .page-card {
    //   scroll-snap-align: start;
    //   scroll-snap-stop: normal;
    // }
  }
  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    // margin: 10px 0 10px 0;
    color: var(--Platform-Grayscale-90, #3c3a41);
    font-family: 'Helvetica Neue';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  }
  &__tag {
    color: var(--color-system-error-primary, #ee0b0b);
    text-align: center;
    /* Platform/Regular/XS */
    font-family: 'Helvetica Neue';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    padding: 0.25rem 0.5rem;
    &.danger {
      color: var(--color-system-error-primary, #ee0b0b);
      border-radius: 0.25rem;
      background: var(--Platform-System-Error-tint, #ffdada);
    }
    &.success {
      color: var(--color-system-success-primary, #06b54c);
      border-radius: 0.25rem;
      background: var(--Platform-System-Success-tint, #d6fae4);
    }
  }
}
