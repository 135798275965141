.document-page__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-top: 1px solid #e5e4eb;
  padding: 0px 50px;
  height: $dash-footer-height;
  font-size: 14px;
}
.page-contrl {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pagination {
  display: flex;
  flex-direction: row;
}

.prev-pg-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  &.active {
    svg path {
      stroke: #18161b;
    }
  }
}

.next-pg-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  &.active {
    svg path {
      stroke: #18161b;
    }
  }
}

.pg-num {
  padding: 8px;
  color: #a2a1a8;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  &.ext {
    pointer-events: none;
  }
  &.active,
  &:hover {
    color: #18161b;
  }
}

.pagination-count {
  color: #a2a1a8;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
