.react-date-picker__wrapper {
  padding: 0.5625rem 1rem;
  border-radius: 0.25rem;
  background: var(--Platform-White, #fff);
  border: none !important;
  font-size: 0.875rem;
}

.react-date-picker__calendar--open {
  font-size: 0.8rem;
}

.react-date-picker__calendar .react-calendar {
  padding: 10px;
  margin-top: 4px;
}

.react-date-picker__button {
  svg {
    width: 15px;
  }
}

.datefield_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.datefield_label {
  color: #838188;
  font-family: 'Helvetica Neue';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}
