.input-wrapper {
  position: relative;
  input {
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #f0f1f1;
    background: #fff;
    width: 100%;
    color: #0e1010;
    transition: 0.2s;
    outline: none;
    font-size: 0.87rem;
    &.icn-lft {
      padding-right: 40px;
    }
    &.inactive {
      color: #a2a1a8;
    }
    &::placeholder {
      color: #a7aaa9;
    }
    &:focus,
    &:active {
      border: 1px solid #0e1010;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  svg,
  img {
    position: absolute;
    right: 20px;
    top: 12px;
  }

  @keyframes onAutoFillStart {
  }

  @keyframes onAutoFillCancel {
  }
  input:-webkit-autofill {
    animation-name: onAutoFillStart;
    transition: background-color 50000s ease-in-out 0s;
  }

  input:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
  }
}
