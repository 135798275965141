.breadcrumb_container {
  display: flex;
}
.breadcrumb__link {
  display: flex;
  flex-direction: row;
  color: var(--breadcrumb-link-color, #838188);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  &:hover {
    color: #4d29db;
    svg path {
      stroke: #4d29db;
    }
  }
}
