.job-card {
  border-radius: 12px;
  border: 1px solid #e5e4eb;
  background: #fff;
  padding: 10px 30px;
  margin: 10px 0;
  cursor: pointer;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    column-gap: 15px;
  }

  .job-desc {
    width: 40%;
  }

  .deli {
    width: 26%;
    .cont {
      padding: var(--number-spacing-xxs, 0.5rem) var(--number-spacing-s, 1rem);
      border-radius: var(--number-radius-s, 0.5rem);
      background: var(--Platform-Grayscale-Hover, #fafafa);
    }
  }

  .prog {
    width: 28%;
  }

  .proj {
    .add-colab {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 100px;
      cursor: pointer;
      z-index: 10;
      background: var(--platform-purple-basic, #7856ff);
      svg {
        margin: 0 !important;
        path {
          stroke: #fff !important;
        }
      }
      &:hover {
        background-color: #4d29db;
      }
      &.active {
        background-color: #d0c8f3;
        svg {
          path {
            stroke: #4d29db;
          }
        }
      }
    }
  }

  // .opts {
  //   width: 10%;
  // }

  .job-status {
    width: 14%;
  }

  .job-desc {
    display: flex;
    flex-direction: row;
    align-items: center;

    .company-service {
      width: 240px;
      min-width: 60px;
    }

    .comp-info {
      width: 180px;
    }

    .comp-logo {
      color: #3c3a41;
      font-size: 0.86rem;
      font-style: normal;
      font-weight: 500;
      width: 180px;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 30px;
        margin-right: 10px;
      }
    }
  }

  .deli,
  .prog,
  .proj {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .txt {
      color: #838188;
      font-size: 0.85rem;
      font-style: normal;
      font-weight: 400;
      margin-right: 14px;
    }

    .val {
      color: #18161b;
      font-size: 0.85rem;
      font-style: normal;
      font-weight: 500;
      // margin-left: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-right: 6px;

        path {
          stroke: #18161b;
        }
      }
    }

    .ic-val {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin: 0 1.5px;
      }
    }
  }

  .opts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    svg {
      margin: 0 5px;
    }
  }

  .job-status {
    display: flex;
    align-items: center;
    column-gap: 12px;
    span {
      font-size: 14px;
      color: #3c3a41;
    }
  }

  .progress {
    svg {
      path {
        fill: none;
        stroke: #e1b000;
      }
    }
  }
}
