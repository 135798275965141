.template-doctype-menu {
  border-radius: 0.375rem;
  border: 1px solid #e5e4eb;
  background: #fff;
  box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
  width: 15.3125rem;
  padding: 0.3rem 0;
  height: 192px;
  overflow: auto;
  &__item {
    display: flex;
    padding: 0.5rem 1rem 0.5rem 0.75rem;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
    cursor: pointer;
    &:hover {
      background: #f8f8fb;
      svg rect {
        stroke: #4d29db;
      }
    }
    span {
      color: #3c3a41;
      font-family: 'Helvetica Neue';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
    }
    &.disabled {
      span {
        color: #a2a1a8;
      }
      svg {
        path {
          stroke: #e5e4eb;
        }
        rect {
          fill: #e5e4eb;
          stroke: #e5e4eb;
        }
      }
    }
  }
}
