.content-editable {
  color: #3c3a41;
  font-family: 'Helvetica Neue';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  padding: 0px 6px;
  text-wrap: nowrap;
  overflow: auto;
  &:focus {
    // border-radius: 8px;
    border: none;
    background: #fff;
    // box-shadow: 0px 0px 0px 4px rgba(0, 138, 64, 0.24);
    outline: none;
  }

  [contenteditable='true']:empty:before {
    content: attr(placeholder);
    display: block;
    color: #aaa;
  }
  &.error{
    border: 1px solid #ee0b0b;
    height: 24px;
    min-width: 60px;
    border-radius: 4px;
  }
}
