.breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  .breadcrumb-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    .step-circle {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 6px 10px;
      justify-content: center;
      align-items: center;
      border-radius: 64px;
      color: #7e8180;
      border: 1px solid #7e8180;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }

    .step-text {
      color: #7e8180;
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      margin-left: 15px;
    }

    &.active {
      .step-circle {
        border: 1px solid #0e1010;
        background: #0e1010;
        color: #fff;
      }
      .step-text {
        color: #0e1010;
      }
    }
    &.visited {
      .step-circle {
        border: 1px solid #7e8180;
        background: #7e8180;
        color: #fff;
      }
      .step-text {
        color: #7e8180;
      }
    }
  }
}
