/* Toast */
.Toastify__toast {
  border-radius: 6px;
  box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
}
.Toastify__toast {
  &--error {
    border: 1px solid #ee0b0b;
    background: linear-gradient(0deg, rgba(255, 218, 218, 0.2) 0%, rgba(255, 218, 218, 0.2) 100%),
      #fff;
  }

  &--info {
    border: 1px solid #e5e4eb;
    background: var(--platform-white, #fff);
  }

  &--warning {
    border: 1px solid #e1b000;
    background: linear-gradient(0deg, #fffcef 0%, #fffcef 100%),
      linear-gradient(0deg, rgba(255, 243, 203, 0.3) 0%, rgba(255, 243, 203, 0.3) 100%), #fff;
  }

  &--success {
    border: 1px solid #06b54c;
    background: linear-gradient(0deg, #f5fef8 0%, #f5fef8 100%),
      linear-gradient(0deg, rgba(214, 250, 228, 0.25) 0%, rgba(214, 250, 228, 0.25) 100%), #fff;
  }

  .toast-msg {
    .title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #3c3a41;
    }
    .msg {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: #838188;
      margin-top: 10px;
      max-height: 75px;
      overflow: auto;
    }
  }
  svg {
    margin-top: 5px;
    path {
      stroke: #3c3a41;
    }
  }
}
