.no-duplicate-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-top: -100px;
  border-right: 1px solid var(--Platform-Grayscale-25, #cccad1);
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
    margin-top: -100px;
  }
  &__title {
    color: var(--Platform-Black, #18161b);
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.625rem;
  }
  &__subtitle {
    color: var(--Platform-Grayscale-55, #838188);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  &__cta {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-top: 20px;
    .manual-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      svg path {
        stroke: #ffffff;
      }
    }
    .back-to-job {
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      color: #3c3a41;
    }
  }
}
