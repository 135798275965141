div.page-zoom-modal {
  width: 100vw;
  background: transparent;

  img {
    // max-width: calc(100% - 100px);
    max-height: 100vh;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .img-container {
    position: relative;
  }

  .emptyBG {
    width: 100vw;
    height: 100vh;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
  }

  .page-zoom-control {
    display: inline-flex;
    padding: 0.4rem 0.8rem;
    align-items: center;
    border-radius: 0.25rem;
    background: rgba(24, 22, 27, 0.4);
    position: absolute;
    z-index: 10000;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    svg {
      cursor: pointer;
    }

    span {
      color: #fff;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      padding: 0.22rem 0.7rem;
      justify-content: center;
      border-radius: 0.125rem;
      background: rgba(24, 22, 27, 0.4);
      margin: 0 16px;
    }
  }

  .close-btn {
    background: var(--platform-purple-basic, #7856ff);
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 100px;

    svg {
      width: 15px;
      height: 15px;

      path {
        stroke: var(--color-grayscale-10, #fff);
      }
    }
  }
}