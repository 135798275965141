.pagecontrol__container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  z-index: 10;
  .edit-btn {
    border-radius: 6.25rem;
    background: var(--Platform-Purple-Tint, #f8f8fb);
    display: flex;
    padding: var(--number-spacing-xs, 0.75rem);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg path {
      stroke: #3c3a41;
    }
  }
}
.pagecontrol {
  border-radius: 6.25rem;
  background: var(--Platform-Purple-Tint, #f8f8fb);
  display: flex;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  justify-content: space-between;
  align-items: center;
  text-wrap: nowrap;
  &__options {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__type {
    display: flex;
    padding: 0.125rem;
    align-items: flex-start;
    gap: 0.25rem;
    border-radius: 6.25rem;
    background: var(--Platform-Grayscale-10, #e5e4eb);
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &-item {
      padding: 0.5rem 1rem;
      cursor: pointer;
      &.active {
        border-radius: 6.25rem;
        background: var(--Platform-Black, #18161b);
        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }

  &__button,
  &_noduplicate {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    margin: 0 20px;
    &-left,
    &-right {
      padding: 0.25rem;
      border-radius: var(--number-radius-xxs, 0.25rem);
      cursor: pointer;
      &.active {
        background: var(--Platform-Purple-Pastel, #d0c8f3);
      }
      &.active,
      &:hover {
        svg {
          path {
            stroke: #4d29db;
          }
        }
      }
    }
    &-text {
      color: var(--Platform-Grayscale-90, #3c3a41);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }
  }
  &_savebtn {
    padding: 0.3125rem 1.5rem;
    border-radius: 0.25rem;
  }
  &__dir {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
    margin-right: 12px;
    pointer-events: all;
    &-left,
    &-right {
      cursor: pointer;
      &.active {
        svg path {
          stroke: #3c3a41;
        }
      }
    }
    svg {
      path {
        stroke: #a2a1a8;
        stroke-width: 1.6;
      }
    }
  }
  .success-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin: 0 15px 0 20px;
    span {
      color: var(--color-system-success-primary, #06b54c);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }
  }

  &__options.saved {
    .pagecontrol__button,
    .pagecontrol_noduplicate,
    .success-text {
      pointer-events: none;
    }
    .pagecontrol__button,
    .pagecontrol_noduplicate {
      &-text {
        color: #a2a1a8 !important;
      }
      svg path {
        stroke: #a2a1a8;
      }
      &-left,
      &-right {
        &.active {
          background: var(--Platform-Purple-Pastel, #cccad1);
          svg path {
            stroke: #838188;
          }
        }
      }
    }
  }

  &.sng {
    .pagecontrol__button,
    .pagecontrol_noduplicate {
      pointer-events: none;
      svg path {
        stroke: #a2a1a8;
      }
    }
  }

  &__message {
    position: absolute;
    left: 50%;
    top: -60px;
    transform: translate(-50%, 0);
    border-radius: 0.25rem;
    background: var(--Platform-System-Success-basic, #06b54c);
    box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.6875rem 1rem;
    column-gap: 10px;
    white-space: nowrap;
    text-wrap: nowrap;
    span {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;
    }
    svg path {
      stroke: #ffffff;
    }
    &.error {
      background: var(--Platform-System-Error-basic, #ee0b0b);
      color: var(--Platform-White, var(--color-grayscale-10, #fff));
    }
  }
}

// #CCCAD1
