.filter-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 15px !important;
    margin-right: 20px;
    border-radius: 6px !important;
    font-size: 14px !important;
    min-width: 110px;

    &:hover {
        background-color: #F2F1F5 !important;
        ;
    }

    &.active {
        background-color: #E5E4EB !important;
        ;
    }

    svg {
        margin-right: 10px;
    }
}

.filter-sidebar__user {
    height: calc(100vh - 384px) !important;
    width: 380px !important;
    right: 40px !important;
}

.filter-sidebar {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: calc(100vh - 236px);
    z-index: 1000;
    top: 165px;
    right: 60px;
    position: fixed;
    opacity: 1;
    border-radius: 0.5rem;
    border: 1px solid #E5E4EB;
    background: #FFF;
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.04);

    .filter-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 20px;
        font-weight: 500;
        color: #18161B;
        padding: 16px 16px 0px 16px;
    }

    .filter-content {
        overflow-y: auto;
        padding: 16px;

        .title {
            color: #56555C;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 2px;
        }

        .sts-filter,
        .decn-filter {
            .checkbox {
                margin-right: 13px;

                rect {
                    stroke: #CCCAD1;
                }
            }
        }

        .sts-filter {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .textfield__input-container {
                height: 2rem;
                padding: 0.375rem 1rem;
            }

            .sts-item {
                display: flex;
                flex-direction: row;
                align-items: center;

                .enclosed-icon {
                    margin-right: 13px;
                    width: 1.5rem !important;
                    height: 1.5rem !important;

                    svg {
                        width: 0.75rem;
                        height: 0.75rem;
                    }

                    &.progress {
                        svg {
                            path {
                                fill: none;
                                stroke: #e1b000;
                            }
                        }
                    }
                }

                .sts-val {
                    color: #3C3A41;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                }

                &.disabled {
                    span {
                        color: #a2a1a8;
                    }

                    svg {
                        path {
                            stroke: #e5e4eb;
                        }

                        rect {
                            fill: #e5e4eb;
                            stroke: #e5e4eb;
                        }
                    }
                }
            }
        }

        .decn-filter {
            margin-top: 15px;

            .decn-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 15px 0;

                .decn-val {
                    width: 120px;
                    color: #3C3A41;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                }

                .outerProgress {
                    height: 20px !important;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 1px;
                }

                .innerProgress {
                    height: 90% !important;
                }
            }
        }

        .user-filter {
            margin-top: 15px;

            .usr-item {
                margin: 12px 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .usr-val {
                    color: #3C3A41;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                }
            }

            .usr-inpt {
                margin-top: 10px;
                margin-bottom: 20px;
                border-radius: 4px;
                border: 1px solid #E5E4EB;
                padding: 0 13px;

                .input-wrapper {
                    margin-top: 0px;

                    .searchico.right {
                        right: 0px;
                    }

                    input {
                        font-size: 0.75rem;
                    }

                    &.reviewer-search input {
                        border: none;
                        padding-left: 0px !important;
                        padding-right: 20px !important;
                    }

                    svg {
                        right: 1px;
                        top: 15px;
                    }
                }

                .usr-lst {
                    max-height: 198px;
                    overflow-y: auto;
                }

                .usr-itm-a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin: 10px 0;
                    cursor: pointer;

                    .close {
                        margin-left: auto;

                        path {
                            stroke: #A2A1A8;
                        }
                    }

                    .member_avatar-letter {
                        font-size: 10px;
                        font-weight: 600;
                        margin-right: 8px;
                    }

                    .usr-tt {
                        font-size: 0.75rem;
                        font-style: normal;
                        font-weight: 400;
                        overflow: hidden;
                        color: #3C3A41;
                        text-overflow: ellipsis;
                    }

                    span {
                        font-size: 0.75rem;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
            }
        }

        .drug-filter {
            margin-top: 15px;
            margin-bottom: 15px;

            .drug-input {
                width: auto;
            }

            .drug-list {
                height: 166px;
                overflow-y: auto;
            }
        }
    }

    .filter-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        .retry {
            color: #3C3A41;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            span {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
            }

            svg {
                margin-right: 10px;

                path {
                    stroke: #3C3A41;
                }
            }
        }
    }
}