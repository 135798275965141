.coverpage-card {
  .page-card {
    background: url('../../../../../common/assets/images/coverpagebg.png') lightgray 50% / cover
      no-repeat;
    backdrop-filter: blur(2px);
    padding: 2.5rem;
    &-job-detail {
      &-logo,
      &-logo-pl {
        height: 4rem;
        margin-bottom: 1rem;
      }
      &-logo {
        width: auto;
      }
      &-logo-pl {
        width: 9.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--number-radius-s, 0.5rem);
        border: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
        span {
          color: var(--Platform-Grayscale-40, #a2a1a8);
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 500;
          line-height: 0.875rem;
        }
      }
      .label {
        color: #000;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.875rem;
        margin-right: 0.75rem;
      }
      .val {
        color: #000;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.875rem;
      }
    }
    .graph-logo {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.3rem;
      margin-top: auto;
      span {
        color: #000;
        font-size: 1.425rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: -0.057rem;
      }
    }
  }
}
