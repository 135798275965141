.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: $dash-menu-bar-height;
  background: var(--platform-purple-tint, #f8f8fb);
  padding: 0 30px;
  .leftbar,
  .rightbar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__dropdown-menu {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: start;
    gap: 0.5rem;
    position: relative;

    &__content-menu-container {
      position: absolute;
      top: 60px;
      display: grid;
      grid-template-columns: 1fr;
      place-content: start;
      place-items: start;
      width: 100%;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      border-radius: 4px;
      border: 1px solid #f0f1f1;
      z-index: 10;
      background-color: white;
    }

    &__item-content {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      align-content: center;
      justify-items: start;
      justify-content: start;
      gap: 0.5rem;
      background-color: white;
      &:hover {
        background-color: #f8f8f8;
      }
      padding-left: 0.5rem;
      padding-right: 0.55rem;
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      width: 100%;
    }

    &__icon {
      width: 1rem;
      height: 1rem;
    }

    &__text {
      font-size: 13px;
      font-weight: 400;
      line-height: 136%;
      letter-spacing: -0.13px;
      color: #0e1010;
    }
  }
}

.dash-menubar {
  .company-image {
    height: 23px;
  }
}
