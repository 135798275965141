.select-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .check-unsel {
    rect {
      stroke: #a2a1a8;
    }
  }
}
