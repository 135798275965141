.file.upload-input {
  cursor: pointer;
  input {
    display: none;
  }
}

.upload-box {
  border-radius: 8px;
  border: 1px dashed rgba(76, 53, 222, 0.4);
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  .info {
    margin-left: 25px;
    .title {
      color: #4c35de;
      font-weight: 500;
    }
    .subtitle {
      color: #7e8180;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
    }
  }
  .desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #4c35de;
    margin-left: auto;
    font-size: 0.9rem;
    font-weight: 500;
    svg {
      margin-right: 10px;
    }
  }
}
