.service-card {
  padding: 28px 24px 20px;
  border-radius: 12px;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;

  .stitle {
    color: #0e1010;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .ctitle {
    color: #7e8180;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &-footer {

    // padding: 20px 0;
    .f-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 0 0;
    }

    .btn-label {
      font-style: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #7e8180;
      font-size: 14px;
      font-weight: 500;

      .time-title {
        margin-right: 6px;
        margin-left: 4px;
      }

      .time-value {
        color: #4a4c4c;
      }
    }

    //override button-disabled styles
    .button.secondary {
      background-color: #0e1010 !important;
      color: #ffffff !important;
    }
  }
}