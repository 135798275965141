.job-detail__section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 0;
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 26px;
    row-gap: 10px;
    flex-wrap: wrap;
  }
  .job-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
