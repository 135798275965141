.file-review-center-duplicate-section {
  position: relative;
}

.file-review-center-duplicate-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100vh - $dash-menu-bar-height);
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  cursor: grab;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}