.doctypes-accordion {
  padding-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  place-content: start;
  place-items: start;
  width: 100%;
  row-gap: 0.75rem;
  &__header-container {
    padding: 0 1rem;
    width: 100%;
  }
  &__header {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: flex-start;
    width: 100%;
    gap: 20px;
    padding-top: 0.75rem;
  }

  &__title-container {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    justify-items: flex-start;
    column-gap: 0.5rem;
  }

  &__outer-indicator {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: 1px solid var(--platform-grayscale-90, #3c3a41);
    background: var(--platform-white, #fff);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__inner-indicator {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
  }

  &__name {
    color: var(--platform-grayscale-90, #3c3a41);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }
  &__number {
    color: var(--platform-grayscale-55, #838188);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__arrow-icon {
    &--rotate-180 {
      transform: rotate(180deg);
    }

    &--rotate-0 {
      transform: rotate(0deg);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: start;
    // overflow-y: scroll;
    width: 100%;
    row-gap: 0.3rem;
    // padding-left: 0.5rem;
  }

  &__item {
    width: 100%;
    column-gap: 0.5rem;
    padding-left: 0.5rem;
    cursor: pointer;
    &:hover {
      padding-right: 0.5rem;
    }
    &-wrapper {
      display: grid;
      grid-auto-flow: column;
      width: 100%;
      column-gap: 0.5rem;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
      justify-items: flex-start;
      padding: 0.2rem 0.2rem 0.2rem 0.4rem;
      &:hover {
        border-radius: var(--number-radius-xxs, 0.25rem);
        background: var(--Platform-Grayscale-Hover, #fafafa);
      }
    }
    &.active {
      &:hover {
        padding-right: 0rem;
      }
      .doctypes-accordion__item-wrapper {
        border-radius: 0.25rem 0 0 0.25rem;
        background: var(--Platform-Grayscale-Hover, #fafafa);
        .doctypes-accordion__item-text {
          color: var(--Platform-Purple-Dark, #4d29db);
        }
      }
    }
  }

  &__item-text {
    color: var(--platform-grayscale-90, #3c3a41);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
