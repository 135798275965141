.my-service-card {
  .jname {
    color: #4a4c4c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 5px 0;

    span {
      font-weight: 500;
      color: #0e1010;
      margin-left: 5px;
      word-wrap: break-word;
    }
  }

  .service-card-footer {
    display: flex;
    flex-direction: column;

    .service-prop {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0;

      .done-prop {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #7e8180;
        font-size: 13px;
        column-gap: 8px;

        span {
          color: var(--color-system-success-primary, #06b54c);

          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          /* 24px */
          letter-spacing: -0.16px;
        }
      }

      .prop {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #7e8180;
        font-size: 13px;

        svg {
          margin-right: 5px;
        }

        span {
          margin-left: 10px;
          color: #4a4c4c;
          font-weight: 500;
        }
      }
    }

    .btns {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      position: relative;
      .customize-btn{
        padding: 11px;
      }
      .download-dropdown {
        padding: 11px;
      }
      .download-btn {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 0;
      }
    }
  }
}
