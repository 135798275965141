.page-pin {
  position: absolute;
  top: -24px;
  cursor: pointer;
  &.right {
    right: -22px;
  }
  &.left {
    left: -22px;
  }
}
