.source-page-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  .page-card {
    border: none;
    box-shadow: 0px 6px 20px -4px rgba(0, 0, 0, 0.05);

    &-header {
      color: var(--Platform-Grayscale-90, #3c3a41);
      font-size: 0.75rem;
      line-height: 1rem;
      font-style: normal;
      font-weight: 500;
      text-align: left;
      width: 100%;
      text-transform: capitalize;
    }

    .magnify {
      .magnify-lens {
        width: 260%;
      }

      &:not(.large-view) {
        .magnify-lens {
          transform: scale(0.7, 0.7);
        }
      }
    }
  }

  &-footer {
    color: var(--Platform-Grayscale-55, #838188);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
  }
}