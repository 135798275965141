.grph_table {
  border: none;
  color: #3c3a41;

  border-collapse: collapse;
  width: 100%;
  // display: block;
  // height: calc(100vh - #{$dash-menu-bar-height} - #{$dash-footer-height} - 180px);
  overflow-y: auto;

  tr {
    border-bottom: 1px solid #e5e4eb;
    &:last-child{
      border-bottom: 0;
    }
  }

  tr:hover,
  tr.active {
    background: #fbfbfd;
    color: #18161b !important;
    // .view-btn {
    //   background: #e5e4eb;
    // }
  }

  td {
    padding: 15px 14px;
  }

  thead {
    position: sticky; /* make the table heads sticky */
    top: 0px; /* table head will be placed from the top of the table and sticks to it */
    // background-color: #ffffff;
    color: #56555c;
    background-color: #f8f8fb;
    z-index: 20;
    tr {
      border: none !important;
    }
    svg path {
      stroke: #56555c;
    }
  }

  tbody {
    overflow-y: auto;
  }

  th {
    padding: 8px 14px;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    &:hover {
      color: #18161b;
      svg path {
        stroke: #18161b;
      }
    }
  }

  col.hover {
    background: #fbfbfd !important;
  }
}
