.job-invite-modal {
  .set-boundary-modal__input-container {
    gap: 10px;
  }
  .close-btn {
    svg path {
      stroke: #18161b;
    }
  }
}
