.job-guide {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.5rem;
  width: 100%;

  &__title {
    color: var(--platform-grayscale-90, #3c3a41);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__description {
    color: var(--platform-grayscale-40, #a2a1a8);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
