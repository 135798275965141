.menu-item-dropdown {
  width: 285px;
  position: absolute;
  top: 30px;
  z-index: 200;
  background: #ffffff;
  border: 1px solid #e5e4eb;
  box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 15px 0px 0px;
  color: #18161b;
}

.menu-item-list {
  border-radius: 0.375rem;
  border: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
  background: var(--Platform-White, #fff);
  box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
  .menu-item {
    display: flex;
    padding: 0.625rem var(--number-spacing-s, 1rem);
    align-items: center;
    gap: var(--number-spacing-xs, 0.75rem);
    align-self: stretch;
    cursor: pointer;
    font-family: 'Helvetica Neue';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    &:hover {
      color: var(--Platform-Purple-Dark, #4d29db);
      background: var(--Platform-Purple-Tint, #f8f8fb);
    }
  }
}
