.tabs {
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding-bottom: 8px;
  &-item {
    white-space: nowrap;
    color: #56555c;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 10px 20px 10px 20px;
    &:first-of-type {
      padding: 10px 20px 10px 0px;
    }
    border-bottom: 2px solid #f8f8fb;
    cursor: pointer;
    &.active {
      border-bottom: 2px solid #7856ff;
      color: #18161b;
      font-style: normal;
      font-weight: 500;
      color: #7856ff;
    }
    &.filler {
      flex: 1;
      cursor: default;
    }
  }
}
