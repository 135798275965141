.notfound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - $dash-menu-bar-height);
  .newtime {
    margin-bottom: 20px;
  }
}
