.dash {
  .dash-menubar {
    border-bottom: 1px solid var(--platform-grayscale-10, #e5e4eb);
  }
}

.layout-body {
  display: flex;
  flex-direction: row;
}
.layout-section {
  width: 100%;
  height: calc(100vh - 72px);
  overflow-y: auto;
}
