.switch-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  border: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  color: var(--Platform-Black, #18161b);
  padding: 0.125rem;
  .s-btn {
    display: flex;
    padding: 0.25rem 1rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.active {
      border-radius: 0.25rem;
      background: var(--Platform-Black, #18161b);
      color: var(--Platform-White, var(--color-grayscale-10, #fff));
    }
  }
}
