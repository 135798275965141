.sidenav {
  position: fixed;
  width: 200px;
  display: flex;
  flex-direction: column;
  transition: width 0.1s ease-in-out;
  height: calc(100vh - $dash-menu-bar-height);
  background-color: #f8f8fb;
  border-right: 1px solid #e5e4eb;
  z-index: 999;
  padding: 10px 10px 0 10px;
}
.sidenav-menu-item {
  display: flex;
  flex-direction: column;
  svg {
    min-width: 24px !important;
  }
}

.sidebar-footer {
  margin-top: auto;
  .collapse {
    font-size: 13px;
    padding: 16px;
    height: 55px;
    white-space: nowrap;
    svg {
      margin-right: 12px;
      min-width: 16px;
    }
    cursor: pointer;
  }
  .collapseMenuClosed {
    transition: visibility 1s ease-in-out;
    display: none;
  }
}

.sidenavClosed .collapse {
  display: flex;
  justify-content: center;
  svg {
    margin: 0;
  }
}

.sidenavClosed {
  @extend .sidenav;
  position: relative;
  transition: width 0.1s ease-in-out;
  width: 65px;
  position: fixed;
}

.sideitem {
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  cursor: pointer;
  color: #56555c;
  text-decoration: none;
  margin-bottom: 5px;
  &.active {
    background-color: #d0c8f3;
    color: #18161b;
    border-radius: 4px;
    svg path {
      stroke: #18161b;
    }
  }
}

.linkText {
  padding-left: 10px;
  visibility: visible;
}

.linkTextClosed {
  @extend .linkText;
  visibility: hidden;
}
