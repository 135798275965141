.qc-textfield {
  display: grid;
  grid-template-columns: 1fr;
  place-content: start;
  place-items: start;
  row-gap: 0.5rem;
  width: 100%;

  &__view {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: start;
    width: 100%;
    row-gap: 0.5rem;

    &__label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &__main-label {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 146%;
      letter-spacing: -0.14px;
      color: #3c3a41;
    }

    &__secondary-label {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 146%;
      letter-spacing: -0.14px;
      color: #a7aaa9;
    }

    &__box {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      align-content: center;
      justify-items: flex-start;
      width: 100%;
      border-radius: 0.375rem;
      border-width: 1px;
      border-style: solid;
      height: 3rem;
      padding: 0.875rem 1rem;
      border-color: #c8cbca;

      font-size: 0.875rem;
      color: #0e1010;
      font-weight: 400;
      line-height: 146%;
      letter-spacing: -0.14px;
    }
  }

  &__actions {
    &__initial-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 0.5rem;
      width: 100%;
      place-content: start;
      place-items: start;
    }
    &__secondary-button {
      display: flex;
      padding: 8px 32px;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 4px;
      background: var(--platform-purple-tint, #f8f8fb);

      color: var(--platform-grayscale-90, #3c3a41);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    &__primary-button {
      display: flex;
      padding: 8px 32px;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 4px;
      background: var(--platform-purple-basic, #7856ff);

      color: var(--platform-white, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      &:disabled {
        background: var(--platform-grayscale-10, #e5e4eb);
        color: var(--platform-grayscale-25, #cccad1);

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &__not-initial-container {
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: 0.5rem;
      width: 100%;
    }
    &__toast-container {
      display: grid;
      grid-auto-flow: column;
      column-gap: 1px;
      align-items: center;
      align-content: center;
      justify-items: flex-end;
      justify-content: flex-end;

      &__text {
        color: var(--color-system-success-primary, #06b54c);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 136%;
        letter-spacing: -0.13px;
      }
    }
  }
}
