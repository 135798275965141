.toc-template-container {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    &--actions{
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
    .title {
      color: var(--Platform-Grayscale-90, #3c3a41);
      font-family: 'Helvetica Neue';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
    }
    .new-sess {
      display: flex;
      height: 32px;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: var(--Platform-Grayscale-90, #3c3a41);
      font-family: 'Helvetica Neue';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
      svg path {
        stroke: var(--Platform-Grayscale-90, #3c3a41);
      }
    }
    .toggle-archive-duplicates{
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      span{
        color: var(--Platform-Grayscale-90, #3c3a41);
        font-family: "Helvetica Neue";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }
  }
  &__body {
    margin-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    height: calc($template-setting-height - 148px - 86px);
    .section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-wrapper {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        padding-left: 1rem;
        .lngdrag-ic {
          opacity: 0;
          height: 0;
        }
        &:hover {
          .lngdrag-ic {
            opacity: 1;
            height: auto;
          }
        }
        .section-content {
          width: 100%;
          display: flex;
          padding: 1rem 0.5rem;
          flex-direction: column;
          justify-content: center;
          gap: var(--number-spacing-xs, 0.75rem);
          border-radius: var(--number-radius-xs, 0.375rem);
          border: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
        }
      }

      &-head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 1rem;
        &__left,
        &__right {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        &__left {
          gap: var(--number-spacing-xs, 0.75rem);
          .content-editable {
            color: var(--Platform-Grayscale-90, #3c3a41);
            font-family: 'Helvetica Neue';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem; /* 142.857% */
            padding: 0 16px 0 0;
            min-width: 45px;
            min-height: 0.875rem;
          }
          .error{
            color: #EE0B0B;
          }
        }
        &__right {
          gap: var(--number-spacing-xs, 1rem);
          .toggle-archived {
            color: var(--Platform-Grayscale-90, #3c3a41);
            font-family: 'Helvetica Neue';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem; /* 142.857% */
          }
          .saved {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--Platform-Purple-Basic, #7856ff);
            font-family: 'Helvetica Neue';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem; /* 142.857% */
            gap: 0.5rem;
            cursor: pointer;
            svg path {
              stroke: #7856ff;
            }
          }
          svg {
            cursor: pointer;
            path {
              stroke: #838188;
            }
          }
        }
      }
      &-body {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        // overflow: auto;
        padding-right: 1rem;
        // overflow: hidden;
        .vertical-line {
          border-left-color: #e5e4eb;
        }
        .docs {
          width: 50%;
          &-head {
            display: flex;
            flex-direction: row;
            height: 2rem;
            padding: 0.5rem 0.25rem 0.5rem 0.75rem;
            justify-content: space-between;
            align-items: center;
            border-radius: 0.25rem;
            background: #f1eefb;
            &.first {
              margin-left: 1rem;
            }
            span {
              color: #4d29db;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 500;
            }
            .icon-btn {
              padding: 4px;
              cursor: pointer;
              border-radius: 0.25rem;
              background: #7856ff;
              svg path {
                stroke: #ffffff;
              }
              &:hover {
                background: #4d29db;
              }
            }
          }
          &-desc {
            color: var(--Platform-Grayscale-40, #a2a1a8);
            font-family: 'Helvetica Neue';
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem; /* 133.333% */
            margin-bottom: 0.75rem;
            margin-top: 0.5rem;
            padding-left: 0.5rem;
          }
          &-list {
            display: flex;
            flex-direction: column;
            // min-height: 40px;
            &.bk {
              gap: 0.25rem;
            }
            &__bk {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }
            &__item,
            &__bk {
              span {
                overflow: hidden;
                color: var(--Platform-Grayscale-75, #56555c);
                text-overflow: ellipsis;
                font-family: 'Helvetica Neue';
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
              }
            }
            &__item {
              display: flex;
              flex-direction: row;
              align-items: center;
              .action-btn {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid #f2f1f5;
                padding: 10px 4px 10px 0.5rem;
              }
              svg {
                cursor: pointer;
                path {
                  stroke: #838188;
                }
              }
              .opt {
                display: flex;
                align-items: center;
                gap: 1rem;
              }
              .drag-ic {
                margin-right: 3px;
                visibility: hidden;
                path {
                  stroke: #cccad1;
                }
              }

              &:hover {
                .drag-ic {
                  visibility: visible;
                }
              }
            }
            &__bk {
              padding: 0rem 0.125rem 0rem 0.5rem;
              gap: 0.25rem;
              .slider {
                width: 38px;
                height: 22px;
                &::before {
                  height: 16px;
                  width: 16px;
                }
              }
            }
          }
        }
        &.grey {
          .docs {
            span {
              color: var(--Platform-Grayscale-25, #cccad1) !important;
            }
            &-desc {
              color: var(--Platform-Grayscale-25, #cccad1) !important;
            }
            &-head {
              background: var(--Platform-Grayscale-Hover, #fafafa) !important;
              .icon-btn {
                background: #cccad1;
                &:hover {
                  background: #cccad1;
                }
              }
            }
          }
        }
      }
      &.disable {
        svg path {
          stroke: var(--Platform-Grayscale-25, #cccad1) !important;
        }
        .section-head__right {
          svg:last-of-type {
            path {
              stroke: #3c3a41 !important;
            }
          }
        }
      }
    }
  }
}
