.workflow-popup-menu {
  position: absolute;
  left: -60px;
  top: 34px;
  width: 260px;
  background: #ffffff;
  border: 1px solid #e5e4eb;
  box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 15px 0;
  z-index: 1000;
  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
  .heading {
    padding: 10px 15px;
    .title {
      color: #18161b;
      font-weight: 500;
    }
    .sub-title {
      font-size: 12px;
      color: #56555c;
      margin-top: 5px;
    }
  }
  .menu-list {
    color: #36343b;
    .list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 15px;
      cursor: pointer;
      &:hover {
        background-color: #f8f8fb;
      }
      .enclosed-icon {
        margin-right: 15px;
      }
    }
  }
}

.workflow-select {
  position: relative;
}

.enclosed-icon {
  align-items: center;
  border-radius: 50%;
  // cursor: pointer;
  display: flex;
  justify-content: center;
}
