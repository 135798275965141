$primary-color: #5e1896;
$secondary-color: #228ab0;
$accent-color: #ae31a4;

$dash-menu-bar-height: 72px;
$dash-footer-height: 48px;

$viewmore-list-width: 160px;

$template-setting-height: 80vh;

:root {
  --toastify-toast-width: 420px;
  --toastify-z-index: 10000000;
  --rt-opacity: 1;
}

[data-theme='default'] {
  color-scheme: default;

  --breadcrumb-link-color: #838188;

  --platform-black: #18161b;
  --platform-grayscale-55: #838188;
  --platform-grayscale-10: #e5e4eb;

  --color-grayscale-40: #e5e7e7;
  --color-grayscale-80: #4a4c4c;

  --platform-purple-tint: #f8f8fb;
  --platform-purple-basic: #7856ff;

  --color-system-success-primary: #06b54c;
}
