.file-review-left-view-footer {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  padding: 1.5rem 1rem;
  width: 100%;
  place-content: start;
  place-items: start;
  border-top: 1px solid var(--platform-grayscale-25, #cccad1);
  background-color: #ffffff;

  &__actions-button {
    display: grid;
    grid-auto-flow: column;
    column-gap: 1rem;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    justify-items: flex-start;

    color: var(--platform-grayscale-90, #3c3a41);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
