.table-wrapper {
  overflow-x: auto;
  height: 100%;
  .doc-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  .doc-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
  }
  .doc-task {
    white-space: nowrap;
  }

  td span {
    // color: #3c3a41;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .pickup-btn {
    width: 6.5625rem;
    text-wrap: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
    &:active {
      svg path {
        stroke: #d0c8f3;
      }
    }
  }
  .view-btn {
    width: 6.5625rem;
    // padding: 0.25rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    span {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
    }
  }
  .user-assign,
  .user-unassign {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    span {
      font-size: 0.875rem;
      max-width: 185px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .user-unassign {
    .icon-btn {
      padding: 2px;
      border-radius: 6.25rem;
      border: 1.5px solid var(--Platform-Grayscale-10, #e5e4eb);
      background: var(--Platform-White, #fff);
    }
  }
  .job-processing {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--number-spacing-s, 1rem);
    height: 100%;
    svg path {
      stroke: #18161b;
      stroke-width: 0.6;
    }
    .p-title {
      color: var(--Platform-Black, #18161b);
      text-align: center;
      font-family: 'Helvetica Neue';
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.625rem;
    }
    .p-subtitle {
      color: var(--Platform-Grayscale-55, #838188);
      text-align: center;
      font-family: 'Helvetica Neue';
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem; /* 150% */
    }
  }
}
