.view-btn {
  width: 88px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // background: #f8f8fb;
  border-radius: 4px;
  color: #3c3a41;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 14px;
  text-decoration: none;
  &.hover {
    background: #e5e4eb;
  }
  span {
    margin-right: 10px;
  }
  &.disabled {
    pointer-events: none;
    color: var(--Platform-Grayscale-25, #cccad1);
    background: var(--Platform-Purple-Tint, #f8f8fb);
    svg path {
      stroke: #cccad1;
    }
  }
}
