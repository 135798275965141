.doctype {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.75rem;
  width: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__header__text-container {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    align-content: center;
    justify-items: flex-start;
    justify-content: flex-start;
    column-gap: 0.5rem;
    width: 100%;
  }

  &__edit-btn {
    margin-left: auto;
    cursor: pointer;
    position: relative;
    &__options {
      position: absolute;
      right: 0;
      border-radius: 6px;
      border: 1px solid var(--Platform-Grayscale-10, #e5e4eb);
      background: var(--Platform-White, #fff);
      box-shadow: 0px 6px 24px -10px rgba(0, 0, 0, 0.08);
      width: 192px;
      z-index: 100;
      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 9px 16px;
        &:first-of-type {
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }
        &:last-of-type {
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        span {
          color: var(--Platform-Grayscale-90, #3c3a41);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          margin-left: 10px;
        }
        &:hover {
          background: var(--Platform-Grayscale-Hover, #fafafa);
          span {
            color: var(--Platform-Purple-Dark, #4d29db);
          }
          svg {
            path {
              stroke: var(--Platform-Purple-Dark, #4d29db);
            }
          }
        }
        &.danger {
          span {
            color: var(--Platform-Purple-Dark, #ee0b0b);
          }
          svg {
            path {
              stroke: var(--Platform-Purple-Dark, #ee0b0b);
            }
          }
        }
      }
    }
  }

  &__title {
    color: var(--platform-black, #18161b);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  &__value {
    color: var(--platform-purple-basic, #7856ff);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  &__content {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    justify-items: flex-start;
    width: 100%;
    column-gap: 0.5rem;
    .member_avatar-letter {
      font-size: 0.5625rem;
    }
  }

  &__identity {
    display: grid;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    justify-items: flex-start;
    width: 100%;
  }

  &__name {
    color: var(--platform-black, #18161b);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  }

  &__subtitle {
    color: var(--platform-grayscale-75, #56555c);
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  }

  &__border {
    width: 0.125rem;
    height: 1.25rem;
    background-color: #e5e4eb;
  }

  &__timestamp {
    color: var(--platform-grayscale-75, #56555c);
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  }
}
