.list-paginationV2 {
  display: flex;
  flex-direction: row;
  padding: 18px;
  justify-content: center;
  align-items: center;
  &_text {
    color: var(--color-grayscale-70, #7e8180);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 146%;
    letter-spacing: -0.00875rem;
  }
  .pagecontrol__dir {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    span {
      background-color: #f8f8f8;
      padding: 0.25rem;
      svg {
        path {
          stroke: #c8cbca;
        }
      }
      &.active svg {
        path {
          stroke: #4a4c4c;
        }
      }
    }
  }
}
