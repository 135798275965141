.task-board {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__title {
    color: var(--platform-black, #18161b);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.625rem;
  }

  &__content {
    color: var(--platform-grayscale-90, #3c3a41);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
}
